var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"live-intro"}},[_c('b-overlay',{attrs:{"variant":"transparent","show":_vm.show,"opacity":1.00,"blur":'2px',"rounded":"lg"}},[_c('b-card',{attrs:{"no-body":""}},[_c('b-card-header',[_c('b-card-title',[_vm._v(_vm._s(_vm.$t('liveIntro')))]),_c('b-button',{staticClass:"save-button",attrs:{"variant":"success"},on:{"click":_vm.validationFormInfo}},[_vm._v(" "+_vm._s(_vm.$t('dataGeneric.save'))+" ")])],1),_c('b-card-body',[_c('b-form',[_c('validation-observer',{ref:"LiveRules",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',[_c('b-form-group',{staticClass:"mb-2 mr-1",attrs:{"label":_vm.$t('dateInit')}},[_c('flat-pickr',{staticClass:"form-control",attrs:{"id":"liveStart","name":"liveStart","placeholder":_vm.$t('dateInit'),"config":{
                      enableTime: true,
                      enableSeconds: true,
                      dateFormat: 'Y-m-d H:i:S',
                      minDate: 'today'
                    }},model:{value:(_vm.liveStart),callback:function ($$v) {_vm.liveStart=$$v},expression:"liveStart"}})],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-form-group',{staticClass:"mb-2 mr-1",attrs:{"label":_vm.$t('dateEnd')}},[_c('flat-pickr',{staticClass:"form-control",attrs:{"id":"liveEnd","name":"liveEnd","placeholder":_vm.$t('dateEnd'),"config":{
                      enableTime: true,
                      enableSeconds: true,
                      dateFormat: 'Y-m-d H:i:S',
                      minDate: _vm.liveStart
                    }},model:{value:(_vm.liveEnd),callback:function ($$v) {_vm.liveEnd=$$v},expression:"liveEnd"}})],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-form-group',{staticClass:"mb-2 mr-1",attrs:{"label":_vm.$t('hasIntro')}},[_c('b-form-checkbox',{attrs:{"switch":""},model:{value:(_vm.hasIntro),callback:function ($$v) {_vm.hasIntro=$$v},expression:"hasIntro"}})],1)],1)],1),(_vm.hasIntro)?_c('b-row',{staticClass:"align-items-center"},[_c('b-col',[_c('validation-provider',{attrs:{"name":"resourceName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{staticClass:"mb-2 mr-1",attrs:{"label":_vm.$t('resPrev')}},[_c('b-form-input',{attrs:{"state":validationContext.errors.length > 0 ? false : null,"disabled":"","placeholder":_vm.$t('resPrev')},model:{value:(_vm.resourceName),callback:function ($$v) {_vm.resourceName=$$v},expression:"resourceName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.validationMessage(validationContext)))])],1)]}}],null,false,869316671)})],1),_c('b-col',{attrs:{"md":"2"}},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.$refs['my-modal'].show()}}},[_vm._v(" "+_vm._s(_vm.$t('select'))+" ")])],1)],1):_vm._e()],1)],1)],1)],1)],1),_c('b-modal',{ref:"my-modal",attrs:{"size":"xl","hide-footer":"","title":_vm.$t('editContent.recSelect')}},[_c('div',{staticClass:"d-block text-center"},[_c('resources-selector',{attrs:{"videotype":'PRE',"msg":'',"sponsor":_vm.isSponsor},on:{"data":_vm.seleccionarRecurso}})],1),_c('b-button',{staticClass:"mt-3",attrs:{"variant":"outline-danger","block":""},on:{"click":_vm.hideModal}},[_vm._v(" "+_vm._s(_vm.$t("dataGeneric.close"))+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }