<template>
  <section id="contentsSelector">
    <b-row class="mt-1 mr-1">
      <b-col style="text-align: justify;">
        <h1>{{ $t("contents.contents") }}</h1>
        <h5 class="text-primary">
          {{ $t("contents.selectContent") }}
        </h5>
      </b-col>
      <b-col class="d-flex justify-content-end">
        <span
          v-if="limitContent > 0"
          class="align-items-center mr-2 mt-1"
        >
          <b-badge
            v-b-tooltip.hover.top="$t('messageContFC', { limitContent })"
            pill
            variant="light-warning"
          >
            {{ $t('uploadCont') }}: <strong> {{ totalLimit }}</strong>
          </b-badge>
        </span>
        <b-button
          style="height: fit-content;"
          :disabled="disabled"
          variant="success"
          @click="seleccionar"
        >
          {{
            $t("select")
          }}
        </b-button>
      </b-col>
    </b-row>
    <b-row class="align-items-center mb-3 mt-3">
      <b-col md="6">
        <b-form-input
          v-model="searchInput"
          :disabled="showClick"
          :placeholder="$t('slider.searchThreeCaracContent')"
          minlength="3"
          @change="searching()"
        />
      </b-col>
      <b-col md="1">
        <b-button
          :disabled="showClick"
          variant="info"
          @click="searching()"
        >
          <feather-icon icon="SearchIcon" />
        </b-button>
      </b-col>
      <b-col
        md="1"
        class="ml-3"
      >
        <b-pagination
          v-if="rows > perPage"
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          size="md"
          class="pt-1"
          @input="fetchData"
        />
      </b-col>
    </b-row>
    <b-row v-if="folders.length == 0 && searchInput.length > 2 && showList != true">
      <b-col
        cols="6"
        class="text-center pr-5"
      >
        <h5 cols="6">
          {{ $t('subscriptions.notFound') }}
        </h5>
      </b-col>
    </b-row>
    <b-row class="d-flex justify-content-between align-items-center">
      <b-col class="text-left">
        <b-overlay
          v-if="searchInput.length > 2 && folders.length == 0"
          variant="transparent"
          :show="showList"
          :opacity="1.00"
          :blur="'2px'"
          rounded="lg"
          tag="ul"
          class="mt-1"
        />
        <h5
          v-if="folders.length > 0"
          class="text-muted"
        >
          <span v-if="multi">
            {{ $t("common.selContList") }}
          </span>
          <span v-else>
            {{ $t("common.multiContList") }}
          </span>
        </h5>
        <span
          v-if="searchInput.length < 3 && folders.length == 0"
          class="text-muted"
        >
          {{ $t('slider.searchThreeCaracContent') }}
        </span>
      </b-col>
      <b-col
        cols="6"
        class=""
      >
        {{ $t("common.prev") }}
        <h3>
          {{ name }}
        </h3>
      </b-col>
    </b-row>
    <b-row style="height:513px">
      <b-col
        style="height:504px"
        md="6"
        class="scroll-list list-group list-group-flush "
      >
        <b-overlay
          variant="transparent"
          :show="showClick"
          :opacity="1.00"
          :blur="'2px'"
          rounded="lg"
          tag="ul"
          class="mt-1"
        >
          <b-list-group-item
            v-for="res in folders"
            v-show="folders.length != 0"
            :id="res.node.id"
            :key="res.node.id"
            :active="checkActive(res.node.id)"
            class="cursor-pointer"
            tag="li"
            @click="
              clickRow(res.node.id)"
            @dblclick="seleccionar"
          >
            <b-row class=" text-justify align-items-center">
              <b-col md="4">
                <b-img
                  id="avatar"
                  rounded="lg"
                  :src="buildImageUrl(res)"
                />
              </b-col>
              <b-col md="2">
                <b-card-text class="mb-0 font-weight-bold">
                  {{ res.node.name }}
                </b-card-text>
              </b-col>
              <b-col class="ml-1">
                <b-badge
                  :class="res.node.state == 'GRE'
                    ? 'badge-light-success'
                    : res.node.state == 'YEW'
                      ? 'badge-light-warning'
                      : 'badge-light-danger'
                  "
                >
                  <span
                    :class="res.node.state == 'GRE'
                      ? 'text-success'
                      : res.node.state == 'YEW'
                        ? 'text-warning'
                        : 'text-danger'
                    "
                    style="text-transform: uppercase; margin-top: 5px;"
                  >
                    {{
                      res.node.state == "GRE"
                        ? $t('ecommerce.public')
                        : res.node.state == "YEW"
                          ? $t('dataGeneric.internal')
                          : $t('ecommerce.NoPublic')
                    }}
                  </span>
                </b-badge>
              </b-col>
              <b-col md="2  ">
                <b-card-text class="mb-0 font-weight-bold">
                  <strong>
                    <b-link
                      style="color:white; text-decoration: underline;"
                      class="cursor-pointer"
                      :href="'/contents/contents/edit/' + res.node.id"
                      target="_blank"
                    >
                      {{ res.node.id }}
                    </b-link>
                  </strong>
                </b-card-text>
              </b-col>
            </b-row>
          </b-list-group-item>
        </b-overlay>
      </b-col>

      <b-col
        id="Video"
        md="6"
        class="d-flex justify-content-center align-items-center"
        style="flex-direction: column;"
      >
        <div
          v-if="videotype == null"
          style="margin-bottom: auto;"
        >
          <p>{{ $t("contents.previewContent") }}</p>
        </div>
        <div
          v-else
          id="containVideo"
          class="mb-2"
        >
          <video
            id="demoSelector"
            width="500"
            height="305"
            controls
            :poster="fileUrl != null
              ? (fileUrl.includes('mp3')
                ? require('@/assets/images/icons/audio.svg')
                : false)
              : directUrl != null ? (directUrl.includes('mp3')
                ? require('@/assets/images/icons/audio.svg')
                : false) : false
            "
            class="video-js vjs-default-skin vjs-big-play-centered"
            data-setup="{&quot;liveui&quot;: true}"
            @loadeddata="videoMaker()"
            @loadstart="videoMaker()"
          >
            <source
              v-if="fileUrl != null"
              :src="fileUrl"
              :type="directUrl != null ? (
                fileUrl.includes('mp4')
                  ? 'video/mp4'
                  : fileUrl.includes('mp3')
                    ? 'audio/mp3'
                    : 'application/x-mpegURL') : ''
              "
            >
            <source
              v-else
              :src="directUrl"
              :type="directUrl != null ? (
                directUrl.includes('mp4')
                  ? 'video/mp4'
                  : directUrl.includes('mp3')
                    ? 'audio/mp3'
                    : 'application/x-mpegURL') : ''
              "
            >
          </video>
        </div>

        <small
          class="text-center"
          style="overflow-wrap: anywhere;"
        >
          {{ isEmpty(fileUrl) ? directUrl : fileUrl }}
        </small>
        <b-overlay
          variant="transparent"
          :show="showClick"
          :opacity="1.00"
          :blur="'2px'"
          rounded="lg"
          tag="ul"
          class="mt-1"
          no-wrap
          no-fade
        />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import 'videojs-markers'
import videojs from 'video.js'
import 'videojs-markers/dist/videojs.markers.css'
import 'videojs-markers/dist/videojs.markers.min.css'
import 'video.js/dist/video-js.css'
import 'video.js/dist/video.min'
import 'video.js/dist/video'
import 'videojs-markers/dist/videojs-markers.min'
// eslint-disable-next-line import/no-extraneous-dependencies
import '@videojs/http-streaming/dist/videojs-http-streaming.min'
// eslint-disable-next-line import/no-extraneous-dependencies
import '@videojs/http-streaming/dist/videojs-http-streaming'
import { utf8ToB64, messageError, isEmpty } from '@/store/functions'
import {
  BPagination,
  BCol,
  BRow,
  BImg,
  BBadge,
  BButton, VBTooltip,
  BListGroupItem,
  BFormInput,
  BCardText,
  BOverlay,
  BLink,
} from 'bootstrap-vue'
import axios from '@axios'
import { getUserData } from '@/auth/utils'

const noCover = require('@/assets/images/backend/nocover.jpeg')

export default {
  components: {
    BFormInput,
    BImg,
    BPagination,
    BBadge,
    BListGroupItem,
    BCol,
    BRow,
    BButton,
    BCardText,
    BOverlay,
    BLink,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    limitContent: { type: Number, default: null },
    multi: {
      type: Boolean,
      default: false,
    },
    sponsor: {
      type: Boolean,
      default: false,
    },
    code: {
      default: '',
    },
    mainCategory: {
      default: '',
    },
    isPremium: { type: Boolean, default: false }, // Contenido de pago
    typeContent: { default: null },
    podcast: { type: Boolean, default: false },
    exclude: { default: null },
  },
  data() {
    return {
      videojs: null,
      showList: false,
      disabled: true,
      video: false,
      showClick: false,
      fileUrl: null,
      name: null,
      selected: null,
      selectedMulti: [],
      cardmoveId: [],
      cardmoveOrder: [],
      orderOrig: null,
      headers: {},
      userData: getUserData(),
      idOrig: null,
      folders: [],
      currentParentFolder: null,
      perPage: 15,
      currentPage: 1,
      rows: null,
      searchInput: '',
      videotype: null,
      directUrl: null,
      image: null,
      stateColor: {
        GRE: this.$t('ecommerce.public'),
        YEW: this.$t('dataGeneric.internal'),
        RED: this.$t('ecommerce.NoPublic'),
        BCK: this.$t('ecommerce.NoPublic'),
      },
      totalLimit: 0,
      infoContent: null,
    }
  },

  mounted() {
    let defaultLang = 'es'
    try {
      defaultLang = this.userData.profile.client.defaultLanguage
      // eslint-disable-next-line no-empty
    } catch (error) { }

    this.headers = {
      'Accept-Language': defaultLang,
      'Content-Language': defaultLang,
    }
    this.fetchData()
  },
  beforeDestroy() {
    if (this.videojs) this.videojs.dispose()
  },

  methods: {
    searching() {
      if (this.searchInput.length >= 3) {
        this.currentPage = 1
        this.fetchData()
      }
      if (this.searchInput.length < 3) {
        this.folders = []
      }
      if (this.searchInput.length === 0) {
        this.fetchData()
      }
    },
    isEmpty,
    seleccionar() {
      this.$emit('data', this.multi ? this.selectedMulti : this.selected)
      this.$emit('info', [this.selected, this.name == null ? this.fileUrl : this.name, this.infoContent])
      this.$emit('url', this.name == null ? this.fileUrl : this.name)
      this.$emit('image', this.image)
    },
    checkActive(id) {
      if (this.multi) {
        if (this.selectedMulti.includes(id)) {
          return true
        }
        return false
      }
      return this.selected === id
    },
    buildImageUrl(folder) {
      return folder.node.imageUrl == null || folder.node.imageUrl.length === 0
        ? noCover
        : folder.node.imageUrl
    },

    routeToEditView(folderId) {
      this.$router.push({ name: 'contents-edit', params: { id: folderId } })
    },

    fetchData() {
      const { headers } = this
      this.showList = true
      const encondedCode = this.mainCategory !== ''
        ? utf8ToB64(`id:${this.mainCategory}`)
        : null
      axios
        .post('', {
          query: `
         query {
                allContents(
                  orderBy:"name",
                  first:${this.perPage},
                  offset:${this.perPage * (this.currentPage - 1)},
                  client:"${this.userData.profile.client.id}",
                  isPremium:${this.isPremium},
                  ${this.sponsor ? `creator:"${utf8ToB64(`id:${this.userData.id}`)}",` : ''}
                  ${this.searchInput != null ? `search:"${this.searchInput}",` : ''}
                  ${encondedCode != null ? `mainCategory: "${encondedCode}",` : ''}
                  ${this.podcast ? `podcast: ${this.podcast},` : ''}
                  ${this.typeContent != null ? `type: "${this.typeContent}",` : ''}
                  ${this.exclude != null ? `exclude: ${this.exclude},` : ''}                
                  ){
                pageInfo{
                  hasPreviousPage
                  hasNextPage
                }
                totalCount
                edgeCount
                edges{                  
                    node{
                      id
                      state
                      name
                      imageUrl
                   }
                  }
                }
              }
        `,
        }, { headers })
        .then(response => {
          messageError(response, this)
          const contents = response.data.data.allContents.edges
          this.folders = contents
          this.rows = response.data.data.allContents.totalCount
          // if (this.folders.length === 0) { this.disabled = true } else { this.disabled = false }
          this.showList = false
        }).catch(error => {
          console.log(error)
        })
    },
    clickRow(id) {
      const { headers } = this
      this.showClick = true
      axios
        .post('', {
          query: `
         query {
          allContents(id:"${id}"){
                edges{                  
                    node{
                      id
                      name
                      contentSlide {
                        totalCount                                
                      }
                      isPremium
                      hashtag
                      state
                      description
                      modifiedAt
                      publishDate
                      expirationDate 
                      emisionDate
                      isAds
                      isActive
                      order
                      mediaLocation
                      image
                      imageUrl
                      contentResources(isInitial:true){
                        edges{
                          node {
                              isInitial
                              content
                              resource{
                              name
                              id      
                              type
                              description      
                              videotype
                              directUrl      
                              file{
                                name
                                fileUrl
                              }
                            }
                          }
                        }
                      }
                   }
                  }
                }
              }
        `,
        }, { headers })
        .then(response => {
          messageError(response, this)
          const valueSelected = response.data.data.allContents.edges[0]
          if (valueSelected) {
            const cRes = valueSelected.node.contentResources.edges[0]

            const demoSelector = document.getElementById('demoSelector')
            const containVideo = document.getElementById('containVideo')

            let show = true
            this.selected = valueSelected.node.id
            this.fileUrl = null
            this.image = null
            this.name = null

            this.videotype = null
            this.directUrl = null
            this.video = false

            this.videotype = null
            this.directUrl = null
            this.video = false

            this.infoContent = valueSelected.node.state
            this.name = valueSelected.node.name
            if (this.multi) {
              document.getElementById(this.selected).classList.toggle('active')
              if (this.selectedMulti.includes(this.selected)) {
                this.selectedMulti = this.selectedMulti.filter(item => item !== this.selected)
                show = false
                this.totalLimit -= 1

                if (demoSelector) {
                  this.videojs.dispose()
                }
              } else {
                this.selectedMulti.push(this.selected)
                this.totalLimit += 1
              }
              if (this.selectedMulti.length > 0) {
                this.disabled = false
              } else {
                this.disabled = true
              }
            } else if (this.selected) {
              this.disabled = false
            } else {
              this.disabled = true
            }
            if (this.limitContent > 0) {
              if (this.totalLimit > this.limitContent) {
                this.disabled = true
              } else {
                this.disabled = false
              }
            }

            if (show) {
              this.image = valueSelected.node.imageUrl
              if (cRes) {
                this.videotype = cRes.node.resource.videotype
                this.directUrl = cRes.node.resource.directUrl
                this.video = true
                this.fileUrl = cRes.node.resource.file?.fileUrl
              } else {
                this.fileUrl = null
                this.video = false
              }
              if (containVideo) {
                try {
                  if (demoSelector) {
                    // eslint-disable-next-line no-underscore-dangle
                    if (this.videojs.error_ != null) this.videoMaker()

                    if (this.directUrl != null && this.directUrl !== '' && this.directUrl.length !== 0) {
                      if (this.directUrl.includes('m3u8')) {
                        this.videojs.src({
                          type: 'application/x-mpegURL',
                          src: this.directUrl,
                        })
                        this.videoMaker()
                      } else {
                        this.videojs.src({
                          type: 'video/mp4',
                          src: this.directUrl,
                        })
                        this.videoMaker()
                      }
                    } else if (this.fileUrl != null && this.fileUrl !== '' && this.fileUrl.length !== 0) {
                      if (this.fileUrl.includes('m3u8')) {
                        this.videojs.src({
                          type: 'application/x-mpegURL',
                          src: this.fileUrl,
                        })
                        this.videoMaker()
                      } else {
                        this.videojs.src({
                          type: 'video/mp4',
                          src: this.fileUrl,
                        })
                        this.videoMaker()
                      }
                    } else {
                      this.videojs.src({
                        type: '',
                        src: '',
                      })
                      this.videoMaker()
                    }
                  } else {
                    const videoElement = document.createElement('video')
                    videoElement.id = 'demoSelector'
                    videoElement.width = 500
                    videoElement.height = 305
                    videoElement.controls = true
                    videoElement.className = 'video-js vjs-default-skin vjs-big-play-centered'
                    videoElement.dataset.setup = '{"liveui": true}'

                    // Agrega los eventos que deseas
                    videoElement.addEventListener('loadeddata', this.videoMaker())
                    videoElement.addEventListener('loadstart', this.videoMaker())

                    // Agrega el video al contenedor
                    containVideo.appendChild(videoElement)
                  }
                } catch (error) { console.log(error) }
              }
            }
          }
          this.showClick = false
        }).catch(error => {
          this.showClick = false

          console.log(error)
        })
    },
    videoMaker() {
      this.videojs = videojs('demoSelector')

      this.videojs.on('ready', function () {
        try {
          this.videojs.vhs = null
          // eslint-disable-next-line no-empty
        } catch (error) { }
      })
    },
  },
}
</script>

<style lang="scss">
#contentsSelector .scroll-list {
  overflow: hidden;
  overflow-y: scroll;
  height: 333px;
}

#avatar {
  width: 10rem;
  height: 5.5rem;
}

#contentsSelector .td {
  width: 50px;
  height: 100px;
}

#contentsSelector .folder {
  min-height: 150px;
  height: 300px;
  cursor: pointer;
}

#contentsSelector .dropContainer {
  border: 3px dashed;
}

#contentsSelector .card-width {
  width: 200px;
}

#contentsSelector .list-group-item {
  transition: all 1s;
}

.check {
  background-color: #212736 !important;
}
</style>
