<template>
  <div>
    <b-button
      variant="outline-dark"
      @click="showTranscodingModal"
    >
      {{ $t(`transcoding.state_transcoding.${transcodingState}`) }}
    </b-button>
    <b-modal
      ref="transcodingModal"
      :title="$t('transcoding.transcoding')"
      size="lg"
    >
      <b-row>
        <b-col
          lg="11"
          class="ml-2"
        >
          <b-form>
            <b-form-row>
              <b-col>
                <b-form-group
                  class="mb-2 mr-1"
                  :label="$t('transcoding.info')"
                >
                  <div class="d-flex">
                    <b-form-input
                      :value="idTranscoding"
                      disabled
                    />
                    <!-- button -->
                    <!-- <b-button
                      v-if="idTranscoding"
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      v-clipboard:success="onCopy"
                      v-clipboard:error="onError"
                      v-clipboard:copy="idTranscoding"
                      variant="primary"
                    >
                      {{ $t("dataGeneric.copy") }}
                    </b-button> -->
                  </div>
                </b-form-group>
              </b-col>
            </b-form-row>
            <b-col
              v-if="modalCouldTranscode"
              md="2"
              style="text-align: end;"
            >
              <b-button
                variant="primary"
                @click="toastTranscoding"
              >
                {{ $t('transcoding.transcode') }}
              </b-button>
            </b-col>
          </b-form>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>

import {
  BButton,
  BCol,
  BFormGroup,
  BFormInput,
  BModal,
  BRow,
  BForm,
  BFormRow,
} from 'bootstrap-vue'

// import Ripple from 'vue-ripple-directive'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from '@axios'
import {
  showToast,
} from '@/store/functions'

export default {
  components: {
    BButton,
    BCol,
    BFormGroup,
    BFormInput,
    BModal,
    BRow,
    BForm,
    BFormRow,
  },
  // directives: {
  //   Ripple,
  // },
  props: {
    showMsgStateTranscode: Boolean,
    transcodingState: {
      type: String,
      default: '',
    },
    idTranscoding: {
      type: String,
      default: '',
    },
    idResource: {
      type: String,
      default: '',
    },
    couldTranscode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      modalCouldTranscode: this.couldTranscode,
      modalTranscodingState: this.transcodingState,
    }
  },
  watch: {
    couldTranscode(newVal) {
      this.modalCouldTranscode = newVal
    },
  },
  methods: {
    showTranscodingModal() {
      this.$refs.transcodingModal.show()
    },
    transcoding() {
      this.modalCouldTranscode = false
      this.modalTranscodingState = 'IPT'
      axios.post('', {
        query: `
          mutation ($idObject: ID!, $isResource: Boolean) {
            transcoding(idObject: $idObject, isResource: $isResource) {
              actionDone
            }
          }
        `,
        variables: {
          idObject: this.idResource,
          isResource: true,
        },
      }).then(response => {
        if (response.data.data.transcoding.actionDone) {
          showToast(this.$t('transcoding.transcodingInProgress'), 1, this)
        } else {
          this.modalCouldTranscode = true
          this.modalTranscodingState = 'NOT'
          showToast(this.$t('transcoding.transcodingError'), 2, this)
        }
      })
    },
    toastTranscoding() {
      this.$swal({
        title: this.$t('code.title'),
        text: this.$t('transcoding.textTranscoding'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('transcoding.confirmTranscoding'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.transcoding()
        }
      })
    },
    // onCopy() {
    //   this.$toast({
    //     component: ToastificationContent,
    //     props: {
    //       // title: this.idTranscoding,
    //       title: this.$t('code.tCopy'),
    //       icon: 'BellIcon',
    //     },
    //   })
    //   console.log(this.idTranscoding)
    // },
    // onError() {
    //   this.$toast({
    //     component: ToastificationContent,
    //     props: {
    //       title: this.$t('code.errorCopy'),
    //       icon: 'BellIcon',
    //     },
    //   })
    // },
  },
}
</script>
