import i18n from '@/libs/i18n'

const es = require('@/assets/images/flags/es.png')
const caEs = require('@/assets/images/flags/ca_es.png')
const glEs = require('@/assets/images/flags/gl_es.png')
const euEs = require('@/assets/images/flags/eu_es.png')
const en = require('@/assets/images/flags/en.png')
const it = require('@/assets/images/flags/it.png')
const fr = require('@/assets/images/flags/fr.png')
const pt = require('@/assets/images/flags/pt.png')
const de = require('@/assets/images/flags/de.png')

export default [
  {
    locale: 'Es',
    img: es,
    name: i18n.t('es'),
  },
  {
    locale: 'CaEs',
    img: caEs,
    name: i18n.t('caEs'),
  },
  {
    locale: 'GlEs',
    img: glEs,
    name: i18n.t('glEs'),
  },
  {
    locale: 'EuEs',
    img: euEs,
    name: i18n.t('euEs'),
  },
  {
    locale: 'En',
    img: en,
    name: i18n.t('en'),
  },
  {
    locale: 'It',
    img: it,
    name: i18n.t('it'),
  },
  {
    locale: 'Fr',
    img: fr,
    name: i18n.t('fr'),
  },
  {
    locale: 'De',
    img: de,
    name: i18n.t('de'),
  },
  {
    locale: 'Pt',
    img: pt,
    name: i18n.t('pt'),
  },]
