<template>
  <div id="moments">
    <b-overlay
      variant="transparent"
      :opacity="1.00"
      :blur="'2px'"
      rounded="lg"
      :show="show"
    >
      <b-card no-body>
        <b-card-header>
          <b-card-title>{{ $t('moments') }}</b-card-title>

          <b-col
            class="d-flex justify-content-end"
          >
            <div v-if="fileUrl">
              <AIChatButton
                ref="moments"
                :view="'moments'"
                :language="language"
                :item="{}"
                :file-url="fileUrl"
                :base="base"
                @updateData="getData()"
              />
            </div>
            <div>
              <b-button
                variant="primary"
                :disabled="action == 'add' || action == 'update'"
                @click="addMoment()"
              >
                <feather-icon
                  icon="FilePlusIcon"
                  size="15"
                />
                {{ $t('generateMoment') }}
              </b-button>
            </div>
          </b-col>
        </b-card-header>
        <b-card-body>
          <b-row>
            <b-col
              md="9"
              align-self="baseline"
              class="
                            embed-responsive
                            embed-responsive-16by9
                            mr-1"
            >
              <video
                v-if="fileUrl"
                id="moment-video"
                controls
                class="video-js vjs-default-skin vjs-big-play-centered embed-responsive-item "
                data-setup="{&quot;liveui&quot;: true}"
                :poster="fileUrl.includes('mp3')
                  ? require('@/assets/images/icons/audio.svg')
                  : ''
                "
                crossOrigin="dashboard.interactvty.com"
                @timeupdate="getTime()"
                @loadstart="videoMaker()"
                @loadeddata="videoMaker()"
              >
                <source
                  :src="fileUrl"
                  :type="fileUrl.includes('m3u8')
                    ? 'application/x-mpegURL'
                    : fileUrl.includes('mp4')
                      ? 'video/mp4'
                      : 'audio/mp3'
                  "
                >
              </video>
            </b-col>

            <b-col>
              <b-overlay
                variant="transparent"
                :opacity="1.00"
                :blur="'2px'"
                rounded="lg"
                :show="show"
              >
                <template #overlay>
                  <div class="d-flex align-items-center">
                    <b-spinner
                      small
                      type="grow"
                      variant="secondary"
                    />
                    <b-spinner
                      type="grow"
                      variant="dark"
                    />
                    <b-spinner
                      small
                      type="grow"
                      variant="secondary"
                    />
                    <!-- We add an SR only text for screen readers -->
                    <span class="sr-only">{{ $t('wait') }}</span>
                  </div>
                </template>
                <b-form @submit.prevent="validationFormInfo">
                  <validation-observer
                    ref="momentRules"
                    tag="form"
                  >
                    <b-form-row>
                      <b-col
                        v-if="action != 'add' && action != 'update'"
                        style="text-align: justify"
                      >
                        <feather-icon
                          icon="InfoIcon"
                          size="16"
                          class="text-info mr-25"
                        />
                        <span class="text-muted">{{ $t('capture') }} <strong>{{ $t('generateMoment') }}</strong>. {{
                          $t('generateMoment2') }}
                          <strong>{{ $t('initMom') }}</strong>{{ $t('generateMoment3') }}</span>
                      </b-col>
                    </b-form-row>
                    <b-form-row>
                      <b-col id="col-canvas-moments">
                        <label
                          v-if="action == 'add' || action == 'update'"
                          class="d-flex justify-content-between"
                        >
                          {{ $t('imageMoment') }}
                        </label>
                        <b-form-group
                          v-if="!imageAud && !canvasImage && (action == 'add' || action == 'update')"
                          class="
                                                                                                                                                                                                                    mb-2
                                                                                                                                                                                                                    mr-1
                                                                                                                                                                                                                    embed-responsive embed-responsive-16by9

                                                                                                                                                                           "
                        >
                          <div
                            class="input-image embed-responsive-item"
                            :style="{
                              background: `url('${imageData}') center/cover no-repeat,white center/cover no-repeat`
                            }"
                          >
                            <span
                              v-if="!imageData"
                              class="placeholder"
                            >
                              <feather-icon
                                icon="ImageIcon"
                                size="80"
                              />
                            </span>
                          </div>
                        </b-form-group>
                        <b-form-group
                          class="
                                                                                                                                mb-2
                                                                                                                                mr-1
                                                                                                                                embed-responsive embed-responsive-16by9

                                                                                                                              "
                        >
                          <canvas
                            v-if="!imageAud"
                            id="canvas-moments"
                            class="input-image embed-responsive-item"
                          />
                          <div
                            v-else
                            class="input-image embed-responsive-item"
                            :style="{
                              background: `url('${imageData}') center/cover no-repeat,white center/cover no-repeat`
                            }"
                          >
                            <span
                              v-if="!imageData"
                              class="placeholder"
                            >
                              <feather-icon
                                icon="ImageIcon"
                                size="80"
                              />
                            </span>
                          </div>
                        </b-form-group>
                      </b-col>
                    </b-form-row>
                    <b-form-row v-if="action == 'add' || action == 'update'">
                      <b-col>
                        <validation-provider
                          v-slot="validationContext"
                          name="name_moment"
                          rules="required"
                        >
                          <b-form-group label-for="name_moment">
                            <label>{{ $t('nameMoment') }}</label>
                            <b-form-input
                              id="name_moment"
                              v-model="name"
                              required
                              maxlength="150"
                              :state="validationContext.errors.length > 0 ? false : null"
                              :placeholder="$t('firstName')"
                            />
                            <small class="text-danger">{{
                              validationMessage(validationContext)
                            }}</small>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                    </b-form-row>
                    <b-form-row v-if="action == 'add' || action == 'update'">
                      <b-col>
                        <b-form-group>
                          <label>{{ $t('dataGeneric.description') }}</label>
                          <b-form-textarea
                            v-model="description"
                            :placeholder="$t('dataGeneric.description')"
                          />
                        </b-form-group>
                      </b-col>
                    </b-form-row>
                    <b-form-row v-if="action == 'add' || action == 'update'">
                      <b-col>
                        <validation-provider
                          v-slot="validationContext"
                          name="time"
                          rules="required"
                        >
                          <b-form-group label-for="time">
                            <label>{{ $t('initMom') }}</label>
                            <b-form-input
                              id="time"
                              v-model="time"
                              type="number"
                              :placeholder="$t('Segundos')"
                              :state="validationContext.errors.length > 0 ? false : null"
                            />
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                    </b-form-row>
                    <b-form-row v-if="action == 'add' || action == 'update'">
                      <b-col>
                        <b-form-group>
                          <label>{{ $t('durMome') }}</label>

                          <b-form-input
                            v-model="duration"
                            :placeholder="$t('Segundos')"
                          />
                        </b-form-group>
                      </b-col>
                    </b-form-row>
                  </validation-observer>
                  <b-form-row
                    v-if="action == 'add' || action == 'update'"
                    class="mt-1 "
                  >
                    <b-col>
                      <b-button
                        type="submit"
                        class="save-button"
                        variant="success"
                      >
                        {{ $t('dataGeneric.save') }}
                      </b-button>
                    </b-col>
                    <b-col>
                      <b-button
                        class="save-button"
                        variant="danger"
                        @click="addMoment('vac')"
                      >
                        {{ $t('dataGeneric.cancel') }}
                      </b-button>
                    </b-col>
                  </b-form-row>
                </b-form>
              </b-overlay>
            </b-col>
          </b-row>
          <b-row
            cols="6"
            class="align-items-center"
          >
            <b-col
              md="3"
              class="d-flex pl-0 mb-1"
              style="justify-content: space-evenly;border-right: ridge;"
            >
              <span
                class="cursor-pointer"
                @click="tiempoVideo('-10')"
              >
                <feather-icon
                  icon="RotateCcwIcon"
                  size="18"
                  class="mr-1"
                />10
              </span>

              <span
                class="cursor-pointer"
                @click="tiempoVideo('-1')"
              >
                <feather-icon
                  icon="RotateCcwIcon"
                  size="18"
                  class="mr-1"
                />1
              </span>

              <span
                class="cursor-pointer"
                @click="tiempoVideo('+1')"
              >
                <feather-icon
                  icon="RotateCwIcon"
                  size="18"
                  class="mr-1"
                />
                1
              </span>

              <span
                class="cursor-pointer d-flex"
                @click="tiempoVideo('+10')"
              >
                <feather-icon
                  icon="RotateCwIcon"
                  size="18"
                  class="mr-1"
                />10
              </span>
            </b-col>
            <b-col
              md="3"
              class="d-flex align-items-center"
              style="justify-content: space-evenly"
              sm="12"
            >
              <b-form-input
                v-model="mHour"
                class="mr-1"
                maxlength="2"
                style="padding: 0; text-align: -webkit-center"
                type="number"
                :min="0"
                @input="lengthHour"
              />

              <strong class="mr-1"> : </strong>

              <b-form-input
                id="mMinute"
                v-model="mMinute"
                maxlength="2"
                class="mr-1"
                style="padding: 0; text-align: -webkit-center"
                type="number"
                :min="0"
                @input="lengthMinute"
              />
              <strong class="mr-1">:</strong>
              <b-form-input
                id="mSecond"
                v-model="mSecond"
                maxlength="2"
                class="mr-1"
                style="padding: 0; text-align: -webkit-center"
                type="number"
                :min="0"
                @input="lengthSecond"
              />
              <b-button
                style="white-space: nowrap"
                @click="timePick()"
              >
                {{ $t('goTo') }}
              </b-button>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
    </b-overlay>

    <b-overlay
      variant="transparent"
      :opacity="1.00"
      :blur="'2px'"
      rounded="lg"
      :show="show"
    >
      <b-card v-if="allMoments.length > 0">
        <b-card-header>
          <b-card-title>{{ $t('genreMoment') }}</b-card-title>
        </b-card-header>
        <b-card-body>
          <b-row class="align-items-center">
            <b-col
              class="p-0"
              lg="1"
              :style="row == 0 ? 'visibility: hidden' : ''"
              md="3"
              cols="3"
            >
              <b-button
                pill
                variant="outline-secondary"
                @click="scrollLeft()"
              >
                <feather-icon
                  icon="ArrowLeftIcon"
                  size="25"
                />
              </b-button>
            </b-col>
            <b-col
              lg="10"
              md="10"
              cols="10"
            >
              <b-row class="scroll">
                <div
                  v-for="i in allMoments"
                  :key="i.node.id"
                  class="m-1"
                >
                  <img
                    :src="i.node.image"
                    class="cursor-pointer video-js vjs-default-skin resourceVideo"
                    alt=""
                    @click="rellenar(i.node)"
                  >
                  <div id="acortarPadre">
                    <div id="acortar">
                      <small>
                        {{ i.node.name }}
                      </small>
                      <p class="text-align-center">
                        {{ $t('momentSeg') }} <b>{{ i.node.time }}</b>
                        <feather-icon
                          icon="Trash2Icon"
                          size="18"
                          class="ml-50 text-danger cursor-pointer"
                          @click="deleteMoments(i.node.id)"
                        />
                      </p>
                    </div>
                  </div>
                </div>
              </b-row>
            </b-col>
            <b-col
              lg="1"
              md="3"
              cols="3"
            >
              <b-button
                v-if="allMoments.length > 3"
                pill
                variant="outline-secondary"
                @click="scrollRight()"
              >
                <feather-icon
                  icon="ArrowRightIcon"
                  size="25"
                />
              </b-button>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
    </b-overlay>
    <b-modal
      ref="generateMoments"
      size="xl"
      hide-footer
      :title="$t('IA.moments')"
    >
      <div class="d-block text-center">
        <generate-moments />
      </div>
      <b-button
        class="mt-3"
        variant="outline-danger"
        block
        @click="$refs['generateMoments'].hide()"
      >
        {{ $t("dataGeneric.close") }}
      </b-button>
    </b-modal>
  </div>
</template>

<script>

import {
  BForm,
  BFormGroup,
  BFormTextarea,
  BFormInput,
  BButton,
  BSpinner,
  BFormRow,
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BOverlay,
} from 'bootstrap-vue'
import axios from '@axios'
import {
  messageError, utf8ToB64, showToast, isEmpty,
} from '@/store/functions'
import { getUserData } from '@/auth/utils'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import videojs from 'video.js'
import 'videojs-markers/dist/videojs.markers.css'
import 'videojs-markers/dist/videojs.markers.min.css'
import 'videojs-markers/dist/videojs-markers'
import 'videojs-markers/dist/videojs-markers.min'
import 'video.js/dist/video-js.css'
import 'video.js/dist/video.min'
import 'video.js/dist/video'
// eslint-disable-next-line import/no-extraneous-dependencies
import '@videojs/http-streaming/dist/videojs-http-streaming.min'
// eslint-disable-next-line import/no-extraneous-dependencies
import '@videojs/http-streaming/dist/videojs-http-streaming'
import AIChatButton from '@/views/common/AIChatButton.vue'
import GenerateMoments from './GenerateMoments.vue'

export default {
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BFormTextarea,
    BRow,
    BCol,
    BSpinner,
    GenerateMoments,
    BCard,
    BFormRow,
    BCardHeader,
    BCardTitle,
    BCardBody,
    AIChatButton,
    BOverlay,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    stop: { default: 0 },
    language: {
      type: String,
      default: null,
    },
    base: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      video: null,
      id: this.$route.params.id,
      allMoments: [],
      fileUrl: null,
      name: null,
      time: 0,
      duration: 1,
      description: null,
      action: '',
      imageData: null,
      image: null,
      defaultLanguage: 'es',
      imageBlob: null,
      idMoment: null,
      canvasImage: false,
      userData: getUserData(),
      canvas: null,
      show: false,
      required,
      mMinute: '00',
      mHour: '00',
      mSecond: '00',
      hour: 0,
      minute: 0,
      second: 0,
      type: 'NOR',
      row: 0,
      imageAud: false,
    }
  },
  watch: {
    mHour() {
      if (this.mHour.length < 2) {
        this.mHour = parseInt(`0${this.mHour}`, 10)
      }
    },
    mMinute() {
      if (this.mHour === this.hour) {
        if (this.mMinute > this.minute) {
          this.mMinute = this.minute
        }
      }
      if (this.mMinute == null) {
        this.mMinute = 0
      }
    },
    mSecond() {
      if (this.mMinute === this.minute) {
        if (this.mSecond > this.second) {
          this.mSecond = this.second
        }
      }
      if (this.mSecond == null) {
        this.mSecond = 0
      }
    },
    canvasImage() {
      this.generar()
    },
    stop() {
      if (this.video) {
        this.video.pause()
      }
    },
  },

  mounted() {
    this.getDefaultLanguage()
    this.getData()
    this.allcontentResource()
  },
  beforeDestroy() {
    if (this.video) this.video.dispose()
  },
  methods: {
    getDefaultLanguage() {
      axios
        .post('', {
          query: `
             query{
             client(id:"${this.userData.profile.client.id}"){
                 defaultLanguage
                 availableLanguages
             }
         }
         `,
        })
        .then(res => {
          this.defaultLanguage = res.data.data.client.defaultLanguage
        })
        .catch(() => {
        })
    },
    tiempoVideo(tipo = null) {
      let wasPlaying = null
      let wasPlaying1 = null
      let wasPlaying2 = null
      let wasPlaying3 = null
      let silencePromise = null
      let silencePromise1 = null
      let silencePromise2 = null
      let silencePromise3 = null
      let seekTime = null
      let seekTime1 = null
      let seekTime2 = null
      let seekTime3 = null
      let duration = null
      let duration2 = null
      switch (tipo) {
        case '-10':
          silencePromise = function (value) {
            if (value !== null && typeof value.then === 'function') {
              value.then(null, () => { })
            }
          }

          wasPlaying = !this.video.paused()
          if (wasPlaying) this.video.pause()

          seekTime = this.video.currentTime() - 10
          if (seekTime <= 0) {
            seekTime = 0
          }
          this.video.currentTime(seekTime)
          if (wasPlaying) silencePromise(this.video.play())

          break
        case '-1':
          silencePromise1 = function (value) {
            if (value != null && typeof value.then === 'function') {
              value.then(null, () => { })
            }
          }

          wasPlaying1 = !this.video.paused()
          if (wasPlaying1) this.video.pause()

          seekTime1 = this.video.currentTime() - 1
          if (seekTime1 <= 0) {
            seekTime1 = 0
          }
          this.video.currentTime(seekTime1)
          if (wasPlaying1) silencePromise1(this.video.play())

          break
        case '+10':
          silencePromise2 = function (value) {
            if (value != null && typeof value.then === 'function') {
              value.then(null, () => { })
            }
          }

          wasPlaying2 = !this.video.paused()
          if (wasPlaying2) this.video.pause()

          seekTime2 = this.video.currentTime() + 10
          duration = this.video.duration()
          if (seekTime2 >= duration) {
            seekTime2 = wasPlaying2 ? duration - 0.001 : duration
          }
          this.video.currentTime(seekTime2)
          if (wasPlaying2) silencePromise2(this.video.play())

          break
        case '+1':
          silencePromise3 = function (value) {
            if (value != null && typeof value.then === 'function') {
              value.then(null, () => { })
            }
          }

          wasPlaying3 = !this.video.paused()
          if (wasPlaying3) this.video.pause()

          seekTime3 = this.video.currentTime() + 1
          duration2 = this.video.duration()
          if (seekTime3 >= duration2) {
            seekTime3 = wasPlaying3 ? duration2 - 0.001 : duration2
          }
          this.video.currentTime(seekTime3)
          if (wasPlaying3) silencePromise3(this.video.play())

          break

        default:
          break
      }
    },
    lengthHour() {
      if (this.mHour.length === 2) {
        document.getElementById('mMinute').focus()
      }
    },
    lengthMinute() {
      if (this.mMinute.length === 2) {
        document.getElementById('mSecond').focus()
      }
    },
    lengthSecond() {
      if (this.mSecond.length === 2) {
        this.timePick()
      }
    },
    deleteMoments(id) {
      this.$swal({
        title: this.$t('code.title'),
        text: this.$t('code.text'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('code.confirm'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.show = true
          const config = {
            timeout: 1000 * 60 * 30,
          }

          const data = new FormData()

          const query = `
          mutation{
            deleteMoment(id:"${id}"){
              found
            }
          }
        `

          data.append('query', query)
          axios
            .post('', data, config)
            .then(res => {
              messageError(res, this)
              this.show = false
              this.getData()
              showToast(this.$t('success'), 1, this)
              this.addMoment('vac')
            })
            .catch(() => {
              this.show = false

              showToast(this.$t('error'), 2, this)
            })
        }
      })
    },
    setTime() {
      const silencePromise = function (value) {
        if (value != null && typeof value.then === 'function') {
          value.then(null, () => { })
        }
      }

      const wasPlaying = !this.video.paused()
      if (wasPlaying) this.video.pause()

      let seekTime = this.mHour * 3600 + this.mMinute * 60 + this.mSecond
      const duration = this.video.duration()
      if (seekTime >= duration) {
        seekTime = wasPlaying ? duration - 0.001 : duration
      }
      this.video.currentTime(seekTime)
      if (wasPlaying) silencePromise(this.video.play())
    },
    getTime() {
      try {
        const time = this.video.currentTime()
        this.time = (time).toFixed()

        if (!Number.isNaN(time)) {
          this.mHour = Math.floor(time / 3600)
          this.mHour = this.mHour < 10 ? this.mHour : this.mHour
          this.mHour = this.pad(this.mHour)
          this.mMinute = Math.floor((time / 60) % 60)
          this.mMinute = this.mMinute < 10 ? this.mMinute : this.mMinute
          this.mMinute = this.pad(this.mMinute)

          this.mSecond = (time % 60).toFixed()
          this.mSecond = this.pad(this.mSecond)
          if (this.action === 'add' || this.action === 'update') {
            this.time = (time).toFixed()
            this.generar()
          }
        }
        // eslint-disable-next-line no-empty
      } catch (error) { }
    },

    pad(n, size) {
      let s = String(n)
      while (s.length < (size || 2)) {
        s = `0${s}`
      }
      return s
    },
    validationFormInfo() {
      return new Promise(resolve => {
        this.$refs.momentRules.validate().then(success => {
          if (success) {
            resolve(true)
            this.actionMoment()
          }
        }).catch(err => { console.log(err) })
      })
    },
    actionMoment() {
      switch (this.action) {
        case 'update':
          this.update(this.idMoment)

          break
        case 'add':

          this.create()
          break
        default:
          break
      }
    },
    update(id) {
      const headers = {
        'Accept-Language': this.defaultLanguage,
        'Content-Language': this.defaultLanguage,
      }
      this.show = true
      const config = {
        timeout: 1000 * 60 * 30,
      }
      const data = new FormData()
      let objectVariables = []
      objectVariables = [
        { title: `name${this.language}`, value: this.name, type: 'String' },
        { title: `description${this.language}`, value: this.description, type: 'String' },
        { title: 'time', value: this.time, type: 'Int' },
        { title: 'duration', value: this.duration === 0 ? 1 : this.duration, type: 'Int' },
      ]

      const variables = {}
      let mutation = 'mutation('
      let query = `{updateMoment(id:"${id}",input:{\n
      `
      objectVariables.forEach(element => {
        variables[element.title] = element.value
        mutation += `$${element.title}:${element.type},\n`
        query += `${element.title}:$${element.title},\n`
      })
      mutation += ')'
      query += `}){
        moments{
              id
            }
        }
      }`
      query = mutation + query

      if (this.imageAud) {
        if (this.image) data.append('image_aud', this.image)
        else { data.append('image_url', this.imageData) }
      } else data.append('image', this.imageBlob)
      data.append('query', query)
      data.append('variables', JSON.stringify(variables))
      axios
        .post('', data, { headers }, config)
        .then(res => {
          messageError(res, this)

          this.show = false

          this.getData()
          showToast(this.$t('success'), 1, this)
          this.addMoment('vac')
        })
        .catch(() => {
          this.show = false

          showToast(this.$t('error'), 2, this)
        })
    },
    create() {
      const headers = {
        'Accept-Language': this.defaultLanguage,
        'Content-Language': this.defaultLanguage,
      }
      this.show = true

      const config = {
        timeout: 1000 * 60 * 30,
      }
      const data = new FormData()
      const encondedId = utf8ToB64(`content:${this.id}`)

      let objectVariables = []
      objectVariables = [
        { title: `name${this.language}`, value: this.name, type: 'String' },
        { title: `description${this.language}`, value: this.description, type: 'String' },
        { title: 'time', value: this.time, type: 'Int!' },
        { title: 'duration', value: this.duration === 0 ? 1 : this.duration, type: 'Int!' },
        { title: 'name', value: this.name, type: 'String!' },
        { title: 'description', value: this.description, type: 'String' },
        { title: 'content', value: encondedId, type: 'ID!' },
      ]

      const variables = {}
      let mutation = 'mutation('
      let query = `{createMoment(input:{\n
        client:"${this.userData.profile.client.id}",\n
        isActive:true,\n
      `
      objectVariables.forEach(element => {
        variables[element.title] = element.value
        mutation += `$${element.title}:${element.type},\n`
        query += `${element.title}:$${element.title},\n`
      })
      mutation += ')'
      query += `}){
        moments{
              id
            }
        }
      }`
      query = mutation + query

      if (this.imageAud) {
        if (this.image) data.append('image_aud', this.image)
        else { data.append('image_url', this.imageData) }
      } else data.append('image', this.imageBlob)

      data.append('query', query)
      data.append('variables', JSON.stringify(variables))
      axios
        .post('', data, { headers }, config)

        .then(res => {
          messageError(res, this)

          this.show = false
          this.getData()
          showToast(this.$t('success'), 1, this)
          this.addMoment('vac')
        })
        .catch(res => {
          console.log(res)
          this.show = false

          showToast(this.$t('error'), 2, this)
        })
    },
    addMoment(type = 'add') {
      this.action = type
      if (!this.imageAud) { this.imageData = null }
      this.name = ''
      this.description = ''
      this.duration = 1

      if (!this.imageAud) {
        if (type === 'add') {
          this.generar()
        } else {
          const canvas = document.getElementById('canvas-moments')
          const context = canvas.getContext('2d')

          const canvasWidth = canvas.width
          const canvasHeight = canvas.height

          context.clearRect(0, 0, canvasWidth, canvasHeight)
          this.imageBlob = null
        }
      }
    },
    rellenar(item) {
      this.idMoment = item.id
      this.imageData = item.image
      this.name = item.name
      this.description = item.description
      this.time = item.time
      this.duration = item.duration
      this.action = 'update'
      this.canvasImage = false
      this.timePick(this.time)
    },
    timePick(seekTime = null) {
      let st = seekTime
      const silencePromise = function (value) {
        if (value != null && typeof value.then === 'function') {
          value.then(null, () => { })
        }
      }
      this.video.play()
      this.video.pause()
      const wasPlaying = !this.video.paused()
      if (wasPlaying) this.video.pause()

      const h = parseInt(this.mHour, 10) * 3600
      const m = parseInt(this.mMinute, 10) * 60
      if (!st) {
        st = parseInt(h, 10) + parseInt(m, 10) + parseInt(this.mSecond, 10)
      }

      const duration = this.video.duration()
      if (st >= duration) {
        st = wasPlaying ? duration - 0.001 : duration
      }
      this.video.currentTime(st)
      if (wasPlaying) silencePromise(this.video.play())
    },
    async generar() {
      this.canvasImage = true
      try {
        const video = document.getElementById('moment-video_html5_api')

        this.canvas = document.getElementById('canvas-moments')

        this.canvas.width = video.videoWidth
        this.canvas.height = video.videoHeight

        this.canvas
          .getContext('2d')
          .drawImage(video, 0, 0, this.canvas.width, this.canvas.height)

        this.imageBlob = await new Promise(resolve => this.canvas.toBlob(resolve, 'image/png'))
        // eslint-disable-next-line no-empty
      } catch (error) {
      }
    },
    validationMessage(data) {
      try {
        if (typeof data !== 'undefined' && data !== null) {
          const keyContent = Object.keys(data.failedRules)
          if (keyContent.includes('required')) {
            return this.$t('required')
          }
        }
      } catch (error) {
        console.log(error)
      }
      return ''
    },

    allcontentResource() {
      const encondedId = utf8ToB64(`content:${this.id}`)
      axios
        .post('', {
          query: `
         query{
          contentsResource(content:"${encondedId}",isInitial:true){
            edges{
              node{
                isInitial
                resource{
                  name
                  videotype
                  inputStream
                  passwordStream
                  id
                  directUrl
                  bucketUrl
                  file{
                    fileUrl
                  }
                }
                content
              }
            }
          }
          }
        `,
        })
        .then(response => {
          messageError(response, this)

          try {
            const contentsResource = response.data.data.contentsResource.edges[0]

            try {
              this.fileUrl = contentsResource.node.resource.file.fileUrl
            } catch (error) {
              this.fileUrl = contentsResource.node.resource.directUrl
            }
            // eslint-disable-next-line no-empty
          } catch (error) { }

          if (this.video) {
            if (this.fileUrl != null && this.fileUrl.length !== 0) {
              if (this.fileUrl.includes('m3u8')) {
                this.video.src({
                  type: 'application/x-mpegURL',
                  src: this.fileUrl,
                })
              } else {
                this.video.src({
                  type: 'video/mp4',
                  src: this.fileUrl,
                })
              }
            }
          }
        })
    },
    videoMaker() {
      this.video = videojs('moment-video')

      this.video.on('ready', function () {
        try {
          this.video.vhs = null
          // eslint-disable-next-line no-empty
        } catch (error) { }
      })
      const time = this.video.duration()

      if (!Number.isNaN(time)) {
        this.hour = Math.floor(time / 3600)
        this.minute = Math.floor((time / 60) % 60)
        this.minute = this.minute < 10 ? `0${this.minute}` : this.minute
        this.second = time % 60
        this.second = this.second < 10 ? `0${this.second}` : `${this.second}`

        try {
          this.second = this.second ? this.second.split('.')[0] : this.second

          // eslint-disable-next-line no-empty
        } catch (error) { }
      }

      if (this.allMoments.length !== 0) {
        try {
          this.video.markers.removeAll()
          this.allMoments.forEach(
            element => {
              this.video.markers.add([
                {
                  time: element.node.time,
                  text: element.node.name,
                  overlayText: element.node.name,
                  duration: element.node.duration,
                  image: element.node.image,
                },
              ])
            },
          )
          // eslint-disable-next-line no-empty
        } catch (e) {
        }
      } else {
        try {
          this.video.markers.removeAll()
          // eslint-disable-next-line no-empty
        } catch (error) { }
      }

      try {
        this.video.markers({
          markerStyle: {
            width: '7px',
            'border-radius': '30%',
            'background-color': 'red',
          },
          markerTip: {
            display: true,
            text(marker) {
              return marker.text
            },
            time(marker) {
              return marker.time
            },
          },
          breakOverlay: {
            display: true,
            displayTime: 5,
            style: {
              width: '100%',
              height: '20%',
              'background-color': 'rgba(0,0,0,0.7)',
              color: 'white',
              'font-size': '17px',
            },
            text(marker) {
              return `<div class="mt-1"><img src="${marker.image}" style="width: 100px;" class="mr-2"><span>${marker.overlayText}</span></div>`
            },
          },
          onMarkerClick() {
            // console.log("Click: " + marker.time);
          },
          onMarkerReached() {
            // console.log("Reached: " + marker.time);
          },
          // markers: this.mark(),
        })
        // eslint-disable-next-line no-empty
      } catch (error) { }
    },
    getData() {
      axios
        .post('', {
          query: `{
                allMoments(orderBy:"time",content:"${utf8ToB64(`id:${this.id}`)}") {
                    edges {
                        node {
                                id
                                time
                                duration
                                image
                                name(lang:"${this.language}",getDefault:true)
                                description(lang:"${this.language}",getDefault:true)
                                content {
                                    id

                                }
                            }
                        }
                    }
                    allContents(id:"${this.id}") {
                    edges {
                        node {
                                id
                                type
                                name(lang:"${this.language}",getDefault:true)
                                imageUrl(lang:"${this.language}",getDefault:true)
                                image
                                alternativeImageUrl(lang:"${this.language}",getDefault:true)
                                alternativeImage
                            }
                        }
                    }
                }`,
        })
        .then(res => {
          messageError(res, this)
          this.allMoments = res.data.data.allMoments.edges
          const content = res.data.data.allContents.edges[0].node
          this.type = content.type
          let mp3 = false
          try {
            mp3 = this.fileUrl.includes('mp3')
            // eslint-disable-next-line no-empty
          } catch (error) { }
          if (this.type === 'AUC' || this.type === 'POD' || mp3) {
            this.imageAud = true
            this.image = !isEmpty(content.alternativeImage) ? content.alternativeImage : content.image
            this.imageData = !isEmpty(content.alternativeImageUrl) ? content.alternativeImageUrl : content.imageUrl
          }
          try {
            this.videoMaker()
            // eslint-disable-next-line no-empty
          } catch (error) { }
        })
    },
    scrollRight() {
      const row = document.getElementsByClassName('scroll')
      if (row.length > 0) {
        row[0].scrollLeft += 100; this.row = row[0].scrollLeft
      }
    },
    scrollLeft() {
      const row = document.getElementsByClassName('scroll')
      if (row.length > 0) {
        row[0].scrollLeft -= 100; this.row = row[0].scrollLeft
      }
    },
  },
}
</script>

<style lang="scss" >
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";

#moments .actions {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: rgb(0 15 51 / 73%);
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
}

#moments .row {
  padding: 1.3rem;
}

#moments .row .col {
  font-size: 1.285rem !important;
}

#moments .card-title {
  font-weight: 500;
}

#moments .scroll {
  max-width: 100%;
  flex-flow: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 120vh;
}

/* #moments .list {
  overflow: hidden;
  overflow-y: scroll;
  height: 38vh;
} */

#moments ::-webkit-scrollbar {

  display: none;
}

#moments .trash:hover {
  color: rgb(109, 0, 0);
}

#moments .edit:hover {
  color: rgb(0, 83, 7);
}

#moments #acortar {
  width: 30vh;
  color: white;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#moments #acortarPadre {
  overflow: hidden;
  white-space: nowrap;
}

.video-js .vjs-tech {
  position: inherit;
  height: inherit !important;
}

#moments .input-image {
  display: block;

  background-size: cover;
  background-position: center center;
}

#moments .placeholder {
  background: #f0f0f0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
  color: #333;
  font-size: 18px;
  font-family: Helvetica;
}
#moments .vjs-break-overlay {
  z-index: 0 !important;
}
</style>
