
import { getUserData } from "@/auth/utils";

export default function configContentType(all = false, isPodcast = false) {
    const user = getUserData()
    let elearningPlatform = false
    let myChannel = false
    try {
        elearningPlatform = user.profile.client.isElearningPlatform
        myChannel = user.profile.client.hasMyChannel
    } catch (error) {

    }
    const typesEdu = {
        LEA: "LEA",
        EXA: "EXA",
        TAS: "TAS",
        FIL: "FIL",
        ZOO: "ZOO",
        MEE: "MEE",
    }
    const typeChannel = {
        MCH: "myChannel",
    }
    let types = {}
    /* all=true, Muestra todos los tipos de la plataforma  */
    if (all) {
        types = {
            NOR: "NOR",
            SER: "SER",
            DIR: "DIR",
            PRO: "PRO",
            CIN: "CIN",
            POD: "POD",
            AUC: "AUC",
        }
    } else {
        /* isPodcast=true, Muestra solo los tipos validos para podcast  */
        if (isPodcast) {
            types = {
                POD: "POD",
                AUC: "AUC",
            }
        } else {
            /* No muestra todos, discrimina los de tipo podcast */
            types = {
                NOR: "NOR",
                SER: "SER",
                DIR: "DIR",
                PRO: "PRO",
                CIN: "CIN",
                AUC: "AUC",

            }
        }

    }

    if (elearningPlatform && !isPodcast) {
        types = { ...types, ...typesEdu }
    }
    if(myChannel && !isPodcast){
        types = { ...types, ...typeChannel }
    }
    return types;
}