<template>
  <div>
    <b-card-header>
      <b-card-title>
        {{ $t("zones.title") }}
      </b-card-title>
      <b-button
        variant="primary"
        @click="createModal()"
      >
        <feather-icon
          icon="FilePlusIcon"
          size="14"
        />
        {{ $t("zones.text") }}
      </b-button>
    </b-card-header>

    <b-row>
      <b-col>
        <b-table
          :fields="fields"
          :items="items"
          show-empty
          :empty-text="$t('resultNotFound')"
        >
          <template #cell(group)="data">
            <span>{{ data.item.node.group.name }}</span>
          </template>
          <template #cell(publishDate)="data">
            <span>{{ formatDate(data.item.node.publishDate) }}</span>
          </template>
          <template #cell(expirationDate)="data">
            <span>{{ formatDate(data.item.node.expirationDate) }}</span>
          </template>
          <template #cell(actions)="data">
            <div class="d-flex align-items-center">
              <b-dropdown
                variant="link"
                no-caret
                toggle-class="p-0"
                dropleft
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="17"
                    class="align-middle text-body"
                  />
                </template>
                <b-dropdown-item @click.prevent="fillModal(data.item.node)">
                  <div class="d-flex">
                    <feather-icon
                      icon="Edit2Icon"
                      class=" mr-2"
                      size="18"
                    />
                    {{ $t('dataGeneric.edit') }}
                  </div>
                </b-dropdown-item>
                <b-dropdown-item @click.prevent="deleteGeo(data.item.node.id)">
                  <div class="d-flex">
                    <feather-icon
                      icon="DeleteIcon"
                      size="18"
                      class=" mr-2"
                    />
                    {{ $t('dataGeneric.delete') }}
                  </div>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </template>
        </b-table>
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col>
        <b-pagination
          v-if="rows > perPage"
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          size="lg"
          @input="getData"
        />
      </b-col>
    </b-row>
    <b-modal
      ref="view-modal"
      size="lg"
      centered
      hide-footer
      :title="$t('zones.text')"
    >
      <form
        ref="form"
        @submit.stop.prevent="handleSubmit"
      >
        <b-overlay
          :show="show"
          variant="transparent"
          :opacity="1.00"
          :blur="'2px'"
          rounded="lg"
        >
          <b-row class="mt-2 mb-2">
            <b-col class="">
              <h3>
                {{ $t('zones.text') }}
              </h3>
              <h6 class="text-primary">
                {{ $t('accessControl.configure') }}
              </h6>
            </b-col>
            <b-col style="text-align: end;">
              <b-button
                variant="success"
                type="submit"
              >
                {{ $t('dataGeneric.save') }}
              </b-button>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                :label="$t('Zones') + ' *'"
                :invalid-feedback="$t('required')"
                :state="nameState"
              >
                <b-form-select
                  id="category"
                  v-model="countryGroupSelected"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="text"
                  :state="nameState"
                  required
                >
                  <b-form-select-option
                    :value="null"
                    disabled
                  >
                    {{ $t('elegirZona') }}
                  </b-form-select-option>
                  <b-form-select-option
                    v-for="cg in countryGroup"
                    :key="cg.node.id"
                    :value="cg.node.id"
                  >
                    <div class="d-flex ">
                      <div class="ml-25 ">
                        <b-card-text class="mb-0 font-weight-bold">
                          {{ cg.node.name }}
                        </b-card-text>
                      </div>
                    </div>
                  </b-form-select-option>
                </b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group>
                <p>
                  {{ $t('contents.datePublication') }}
                  <feather-icon
                    v-if="publishDate"
                    icon="XCircleIcon"
                    size="20"
                    class="text-danger cursor-pointer ml-1 "
                    @click="publishDate = null"
                  />
                </p>
                <flat-pickr
                  v-model="publishDate"
                  class="form-control"
                  :placeholder="$t('contents.datePublication')"
                  :config="{
                    enableTime: true,
                    enableSeconds: true,
                    dateFormat: 'Y-m-d H:i:S',

                  }"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group>
                <p>
                  {{ $t('contents.dateFinishing') }}
                  <feather-icon
                    v-if="expirationDate"
                    icon="XCircleIcon"
                    size="20"
                    class="text-danger cursor-pointer ml-1 "
                    @click="expirationDate = null"
                  />
                </p>
                <flat-pickr
                  v-model="expirationDate"
                  class="form-control"
                  :placeholder="$t('contents.dateFinishing')"
                  :config="{
                    enableTime: true,
                    enableSeconds: true,
                    dateFormat: 'Y-m-d H:i:S',
                    minDate: publishDate

                  }"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-overlay>
      </form>
      <b-button
        class="mt-1"
        variant="outline-danger"
        block
        @click.stop.prevent="opModal('view-modal', 1)"
      >
        {{ $t("dataGeneric.close") }}
      </b-button>
    </b-modal>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BFormSelectOption,
  BFormSelect,
  BButton,
  BFormGroup,
  BCardText,
  BTable,
  BCardTitle,
  BPagination,
  BDropdown,
  BDropdownItem,
  BCardHeader,
  BOverlay,
} from 'bootstrap-vue'
import { getUserData } from '@/auth/utils'
import axios from '@axios'
import { messageError, showToast, utf8ToB64 } from '@/store/functions'
import flatPickr from 'vue-flatpickr-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'flatpickr/dist/flatpickr.css'

export default {
  components: {
    BCardText,
    BRow,
    BButton,
    BCol,
    BFormSelect,
    BFormSelectOption,
    BCardTitle,
    BTable,
    BOverlay,
    BFormGroup,
    flatPickr,
    BPagination,
    BDropdown,
    BDropdownItem,
    BCardHeader,
  },
  props: {
    typeElement: {
      type: String,
      default: null,
    },
    op: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      userData: getUserData(),
      show: false,
      overlay: false,
      isoCountry: [],
      selectedCountries: [],
      allCountries: [],
      isActive: true,
      nameZone: '',
      nameState: null,
      perPage: 20,
      dropdownVisible: false,
      currentPage: 1,
      searchCountries: '',
      rows: null,
      fields: [
        { key: 'group', label: this.$t('Zones') },
        { key: 'publishDate', label: this.$t('contents.datePublication') },
        { key: 'expirationDate', label: this.$t('contents.dateFinishing') },
        { key: 'actions', label: this.$t('zones.label4') },
      ],
      items: [],
      idElement: this.$route.params.id,
      id: null,
      publishDate: null,
      expirationDate: null,
      countryGroup: [],
      countryGroupSelected: null,
      today: new Date(),
    }
  },
  mounted() {
    this.getData()
    this.getZones()
  },
  methods: {
    handleSubmit() {
      if (this.id !== null) { this.editBlocking() } else this.createBlocking()
    },
    fechaFormato(value) {
      const fecha = new Date(value)
      return value != null ? fecha : value
    },
    createBlocking() {
      if (!this.checkFormValidity()) {
        return
      }
      let query = ''
      switch (this.typeElement) {
        case 'CON':
          query = `
                mutation($idElement: ID!,$group: ID!, $publishDate: DateTime,$expirationDate: DateTime){
                    createContentCountryGroup(input:{
                        content:$idElement,
                        group:$group,
                        publishDate:$publishDate,
                        expirationDate:$expirationDate,
                    }
                        ){
                            contentCountryGroup{
                            id
                            group {
                              id
                              name
                            }
                            publishDate
                            expirationDate
                        }
                    }
                }`
          break
        case 'CAT':
          query = `
                mutation($idElement: ID!,$group: ID!, $publishDate: DateTime,$expirationDate: DateTime){
                    createCategoryCountryGroup(input:{
                        category:$idElement,
                        group:$group,
                        publishDate:$publishDate,
                        expirationDate:$expirationDate,
                    }
                        ){
                        categoryCountryGroup{
                            id
                            group {
                              id
                              name
                            }
                            publishDate
                            expirationDate
                        }
                    }
                }`
          break
        case 'INT':
          query = `
                mutation($idElement: ID!,$group: ID!, $publishDate: DateTime,$expirationDate: DateTime){
                    createInteractivityCountryGroup(input:{
                        interactivity:$idElement,
                        group:$group,
                        publishDate:$publishDate,
                        expirationDate:$expirationDate,
                    }
                        ){
                          interactivityCountryGroup{
                            id
                            group {
                              id
                              name
                            }
                            publishDate
                            expirationDate
                        }
                    }
                }`
          break
        case 'SLD':
          query = `
                mutation($idElement: ID!,$group: ID!, $publishDate: DateTime,$expirationDate: DateTime){
                    createSlideCountryGroup(input:{
                        slide:$idElement,
                        group:$group,
                        publishDate:$publishDate,
                        expirationDate:$expirationDate,
                    }
                        ){
                          slideCountryGroup{
                            id
                            group {
                              id
                              name
                            }
                            publishDate
                            expirationDate
                        }
                    }
                }`

          break
        case 'CAM':
          query = `
                mutation($idElement: ID!,$group: ID!, $publishDate: DateTime,$expirationDate: DateTime){
                    createCampaingCountryGroup(input:{
                        campaing:$idElement,
                        group:$group,
                        publishDate:$publishDate,
                        expirationDate:$expirationDate,
                    }
                        ){
                          campaingCountryGroup{
                            id
                            group {
                              id
                              name
                            }
                            publishDate
                            expirationDate
                        }
                    }
                }`

          break
        case 'DISC':
          query = `
                mutation($idElement: ID!,$group: ID!, $publishDate: DateTime,$expirationDate: DateTime){
                    createDisplayCampaingCountryGroup(input:{
                        campaignDisplay:$idElement,
                        group:$group,
                        publishDate:$publishDate,
                        expirationDate:$expirationDate,
                    }
                        ){
                          displayCampaignCountryGroup{
                            id
                            group {
                              id
                              name
                            }
                            publishDate
                            expirationDate
                        }
                    }
                }`

          break

        default:
          break
      }

      const variables = {
        idElement: this.idElement,
        group: this.countryGroupSelected,
        publishDate: this.publishDate ? this.fechaFormato(this.publishDate) : null,
        expirationDate: this.expirationDate ? this.fechaFormato(this.expirationDate) : null,
        client: this.userData.profile.client.id,
      }
      axios.post('', { query, variables }).then(r => {
        messageError(r, this)
        showToast(
          this.$t('success'),
          1, this,
        )
        this.getData()

        this.opModal('view-modal', 1)
      }).catch(() => {
        showToast(this.$t('error'), 2, this)
      })
    },
    editBlocking() {
      if (!this.checkFormValidity()) {
        return
      }
      let query = ''
      switch (this.typeElement) {
        case 'CON':
          query = `
                mutation($id: ID!,$idElement: ID!,$group: ID!, $publishDate: DateTime,$expirationDate: DateTime){
                    updateContentCountryGroup(id: $id,input:{
                        content:$idElement,
                        group:$group,
                        publishDate:$publishDate,
                        expirationDate:$expirationDate,
                    }
                        ){
                            contentCountryGroup{
                            id
                            group {
                            id
                            name
                            }
                            publishDate
                            expirationDate
                        }
                    }
                }`
          break
        case 'CAT':
          query = `
                mutation($id: ID!,$idElement: ID!,$group: ID!, $publishDate: DateTime,$expirationDate: DateTime){
                    updateCategoryCountryGroup(id: $id,input:{
                        category:$idElement,
                        group:$group,
                        publishDate:$publishDate,
                        expirationDate:$expirationDate,
                    }
                        ){
                        categoryCountryGroup{
                            id
                            group {
                            id
                            name
                            }
                            publishDate
                            expirationDate
                        }
                    }
                }`
          break
        case 'INT':
          query = `
                mutation($id: ID!,$idElement: ID!,$group: ID!, $publishDate: DateTime,$expirationDate: DateTime){
                    updateInteractivityCountryGroup(id: $id,input:{
                        interactivity:$idElement,
                        group:$group,
                        publishDate:$publishDate,
                        expirationDate:$expirationDate,
                    }
                        ){
                        interactivityCountryGroup{
                            id
                            group {
                              id
                              name
                            }
                            publishDate
                            expirationDate
                        }
                    }
                }`

          break
        case 'SLD':
          query = `
                mutation($id: ID!,$idElement: ID!,$group: ID!, $publishDate: DateTime,$expirationDate: DateTime){
                    updateSlideCountryGroup(id: $id,input:{
                        slide:$idElement,
                        group:$group,
                        publishDate:$publishDate,
                        expirationDate:$expirationDate,
                    }
                        ){
                        slideCountryGroup{
                            id
                            group {
                              id
                              name
                            }
                            publishDate
                            expirationDate
                        }
                    }
                }`
          break
        case 'CAM':
          query = `
                mutation($id: ID!,$idElement: ID!,$group: ID!, $publishDate: DateTime,$expirationDate: DateTime){
                    updateCampaingCountryGroup(id: $id,input:{
                        campaing:$idElement,
                        group:$group,
                        publishDate:$publishDate,
                        expirationDate:$expirationDate,
                    }
                        ){
                          campaingCountryGroup{
                            id
                            group {
                              id
                              name
                            }
                            publishDate
                            expirationDate
                        }
                    }
                }`

          break
        case 'DISC':
          query = `
                mutation($id: ID!,$idElement: ID!,$group: ID!, $publishDate: DateTime,$expirationDate: DateTime){
                    updateDisplayCampaingCountryGroup(id: $id,input:{
                        campaignDisplay:$idElement,
                        group:$group,
                        publishDate:$publishDate,
                        expirationDate:$expirationDate,
                    }
                        ){
                          displayCampaignCountryGroup{
                            id
                            group {
                              id
                              name
                            }
                            publishDate
                            expirationDate
                        }
                    }
                }`

          break
        default:
          break
      }

      const variables = {
        id: this.id,
        idElement: this.idElement,
        group: this.countryGroupSelected,
        publishDate: this.publishDate ? this.fechaFormato(this.publishDate) : null,
        expirationDate: this.expirationDate ? this.fechaFormato(this.expirationDate) : null,
        client: this.userData.profile.client.id,
      }
      axios.post('', { query, variables }).then(r => {
        messageError(r, this)
        showToast(
          this.$t('success'),
          1, this,
        )
        this.getData()

        this.opModal('view-modal', 1)
      }).catch(() => {
        showToast(this.$t('error'), 2, this)
      })
    },
    deleteGeo(id) {
      this.$swal({
        title: this.$t('code.title'),
        text: this.$t('deleteGeo'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('code.confirm'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          let query = ''
          switch (this.typeElement) {
            case 'CON':
              query = `mutation{ 
              deleteContentCountryGroup(id:"${id}"){
                  found
                }
            }`
              break
            case 'CAT':
              query = `mutation{ 
              deleteCategoryCountryGroup(id:"${id}"){
                  found
                }
            }`
              break
            case 'INT':
              query = `mutation{ 
              deleteInteractivityCountryGroup(id:"${id}"){
                  found
                }
            }`
              break
            case 'SLD':
              query = `mutation{ 
              deleteSlideCountryGroup(id:"${id}"){
                  found
                }
            }`
              break
            case 'CAM':
              query = `mutation{ 
              deleteCampaingCountryGroup(id:"${id}"){
                  found
                }
            }`
              break
            case 'DISC':
              query = `mutation{ 
              deleteDisplayCampaingCountryGroup(id:"${id}"){
                  found
                }
            }`
              break

            default:
              break
          }

          axios
            .post('', {
              query,
            }).then(res => {
              messageError(res, this)
              showToast(this.$t('success'), 1, this)
              this.getData()
            }).catch(() => {
              showToast(this.$t('error'), 2, this)
            })
        }
      }).catch(() => {

      })
    },
    formatDate(value) {
      const date = new Date(value)
      const options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: false,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      }

      return value != null
        ? new Intl.DateTimeFormat('default', options).format(date)
        : value
    },
    getZones() {
      const query = `{
                countryGroup(client:"${this.userData.profile.client.id}") {
                    totalCount
                    edges {
                    node {
                        id
                        name
                    }
                    }
                }
            }`
      axios.post('', { query }).then(r => {
        messageError(r, this)
        this.countryGroup = r.data.data.countryGroup.edges
      })
    },
    fillModal(node) {
      const {
        id, group, publishDate, expirationDate,
      } = node
      this.publishDate = this.fechaFormato(publishDate)
      this.expirationDate = this.fechaFormato(expirationDate)
      this.countryGroupSelected = group?.id
      this.id = id
      this.opModal('view-modal', 0)
    },

    getData() {
      const id = utf8ToB64(`id:${this.idElement}`)
      let query = ''
      switch (this.typeElement) {
        case 'CON':
          query = `
                {
                   geoblocking: contentCountryGroup(   
                    first:${this.perPage},
                    offset:${this.perPage * (this.currentPage - 1)},
                    content:"${id}"){
                      totalCount
                        edges{
                        node{
                            id
                            group {
                            id
                            name
                            }
                            publishDate
                            expirationDate
                        }
                        }
                    }
                }`
          break
        case 'CAT':
          query = `
                {
                    geoblocking: categoryCountryGroup(
                      first:${this.perPage},
                      offset:${this.perPage * (this.currentPage - 1)},  
                      category:"${id}"
                      ){
                      totalCount
                        edges{
                        node{
                            id
                            group {
                            id
                            name
                            }
                            publishDate
                            expirationDate
                        }
                     }
                    }
                }`
          break
        case 'INT':
          query = `
                {
                   geoblocking: interactivityCountryGroup(   
                    first:${this.perPage},
                    offset:${this.perPage * (this.currentPage - 1)},
                    interactivity:"${id}"){
                      totalCount
                        edges{
                        node{
                            id
                            group {
                              id
                              name
                            }
                            publishDate
                            expirationDate
                        }
                        }
                    }
                }`
          break
        case 'SLD':
          query = `
                {
                   geoblocking: slideCountryGroup(   
                    first:${this.perPage},
                    offset:${this.perPage * (this.currentPage - 1)},
                    slide:"${id}"){
                      totalCount
                        edges{
                        node{
                            id
                            group {
                              id
                              name
                            }
                            publishDate
                            expirationDate
                        }
                        }
                    }
                }`
          break
        case 'CAM':
          query = `
                {
                   geoblocking: campaingCountryGroup(   
                    first:${this.perPage},
                    offset:${this.perPage * (this.currentPage - 1)},
                    campaing:"${id}"){
                      totalCount
                        edges{
                        node{
                            id
                            group {
                              id
                              name
                            }
                            publishDate
                            expirationDate
                        }
                        }
                    }
                }`
          break
        case 'DISC':
          query = `
                {
                   geoblocking: displayCampaignCountryGroup(   
                    first:${this.perPage},
                    offset:${this.perPage * (this.currentPage - 1)},
                    campaignDisplay:"${id}"){
                      totalCount
                        edges{
                        node{
                            id
                            group {
                              id
                              name
                            }
                            publishDate
                            expirationDate
                        }
                        }
                    }
                }`
          break

        default:
          break
      }

      axios.post('', { query }).then(r => {
        messageError(r, this)
        this.items = r.data.data.geoblocking.edges
        this.rows = r.data.data.geoblocking.totalCount
      })
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity()
      this.nameState = valid
      return valid
    },
    createModal() {
      this.publishDate = null
      this.expirationDate = null
      this.countryGroupSelected = null
      this.nameState = null
      this.id = null
      this.opModal('view-modal', 0)
    },
    opModal(type, op) {
      if (op === 0) {
        this.$refs[type].show()
      } else {
        this.$refs[type].hide()
      }
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
