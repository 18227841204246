<template>
  <div id="cueAdPoints">
    <b-overlay
      variant="transparent"
      :opacity="1.00"
      :blur="'2px'"
      rounded="lg"
      :show="show"
    >
      <b-card>
        <b-card-header>
          <b-card-title>Ad Cue Points</b-card-title>
          <b-col style="text-align: end">
            <b-button
              variant="primary"
              :disabled="action == 'add' || action == 'update'"
              @click="addCuePoints()"
            >
              <feather-icon
                icon="FilePlusIcon"
                size="15"
              />
              {{ $t('generateCuePoints') }}
            </b-button>
          </b-col>
        </b-card-header>
        <b-card-body>
          <b-row>
            <b-col
              md="9"
              align-self="baseline"
              class="
                            embed-responsive
                            embed-responsive-16by9
                            mr-1"
            >
              <video
                v-if="fileUrl"
                id="cue-video"
                controls
                class="video-js vjs-default-skin vjs-big-play-centered embed-responsive-item "
                data-setup="{&quot;liveui&quot;: true}"
                :poster="fileUrl.includes('mp3')
                  ? require('@/assets/images/icons/audio.svg')
                  : ''
                "
                crossOrigin="dashboard.interactvty.com"
                @timeupdate="getTime()"
                @loadstart="videoMaker()"
                @loadeddata="videoMaker()"
              >
                <source
                  :src="fileUrl"
                  :type="fileUrl.includes('m3u8')
                    ? 'application/x-mpegURL'
                    : fileUrl.includes('mp4')
                      ? 'video/mp4'
                      : 'audio/mp3'
                  "
                >
              </video>
            </b-col>

            <b-col>
              <b-overlay
                variant="transparent"
                :opacity="1.00"
                :blur="'2px'"
                rounded="lg"
                :show="show"
              >
                <template #overlay>
                  <div class="d-flex align-items-center">
                    <b-spinner
                      small
                      type="grow"
                      variant="secondary"
                    />
                    <b-spinner
                      type="grow"
                      variant="dark"
                    />
                    <b-spinner
                      small
                      type="grow"
                      variant="secondary"
                    />
                    <span class="sr-only">{{ $t('wait') }}</span>
                  </div>
                </template>
              </b-overlay>
            </b-col>
          </b-row>
          <b-row
            cols="6"
            class="align-items-center"
          >
            <b-col
              md="3"
              class="d-flex pl-0 mb-1"
              style="justify-content: space-evenly;border-right: ridge;"
            >
              <span
                class="cursor-pointer"
                @click="tiempoVideo('-10')"
              >
                <feather-icon
                  icon="RotateCcwIcon"
                  size="18"
                  class="mr-1"
                />10
              </span>

              <span
                class="cursor-pointer"
                @click="tiempoVideo('-1')"
              >
                <feather-icon
                  icon="RotateCcwIcon"
                  size="18"
                  class="mr-1"
                />1
              </span>

              <span
                class="cursor-pointer"
                @click="tiempoVideo('+1')"
              >
                <feather-icon
                  icon="RotateCwIcon"
                  size="18"
                  class="mr-1"
                />
                1
              </span>

              <span
                class="cursor-pointer d-flex"
                @click="tiempoVideo('+10')"
              >
                <feather-icon
                  icon="RotateCwIcon"
                  size="18"
                  class="mr-1"
                />10
              </span>
            </b-col>
            <b-col
              md="3"
              class="d-flex align-items-center"
              style="justify-content: space-evenly"
              sm="12"
            >
              <b-form-input
                v-model="mHour"
                class="mr-1"
                maxlength="2"
                style="padding: 0; text-align: -webkit-center"
                type="number"
                :min="0"
                @input="lengthHour"
              />

              <strong class="mr-1"> : </strong>

              <b-form-input
                id="mMinute"
                v-model="mMinute"
                maxlength="2"
                class="mr-1"
                style="padding: 0; text-align: -webkit-center"
                type="number"
                :min="0"
                @input="lengthMinute"
              />
              <strong class="mr-1">:</strong>
              <b-form-input
                id="mSecond"
                v-model="mSecond"
                maxlength="2"
                class="mr-1"
                style="padding: 0; text-align: -webkit-center"
                type="number"
                :min="0"
                @input="lengthSecond"
              />
              <b-button
                style="white-space: nowrap"
                @click="timePick()"
              >
                {{ $t('goTo') }}
              </b-button>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
    </b-overlay>

    <b-overlay
      variant="transparent"
      :opacity="1.00"
      :blur="'2px'"
      rounded="lg"
      :show="show"
    >
      <b-card v-if="allCueAdPoints.length > 0">
        <b-card-header>
          <b-card-title>{{ $t('cuePointsContent') }}</b-card-title>
        </b-card-header>
        <b-card-body>
          <b-row class="align-items-center">
            <!-- { key: 'vastTagUrl', label: $t('urlVast') },
              { key: 'vastTagUrlMobile', label: $t('urlVastMobile') },
              { key: 'vastTagUrlTv', label: $t('urlVastTv') }, -->
            <b-table
              :fields="[
                { key: 'name', label: $t('administrators.name') },
                { key: 'startTime', label: $t('startTimeCode') + ' (hh:mm:ss)' },
                { key: 'isActive', label: $t('dataGeneric.active') },
                { key: 'edit', label: $t('Edit') },
              ]"
              :items="allCueAdPoints"
              show-empty
              fixed
              responsive
              hover
              no-border-collapse
              :empty-text="$t('resultNotFound')"
            >
              <template #cell(name)="data">
                <span>{{ data.item.node.name }}</span>
              </template>
              <template #cell(startTime)="data">
                <span>{{ data.item.node.startTimecode }}</span>
              </template>
              <template #cell(vastTagUrl)="data">
                <span>{{ data.item.node.vastTagUrl }}</span>
              </template>
              <template #cell(vastTagUrlMobile)="data">
                <span>{{ data.item.node.vastTagUrlMobile }}</span>
              </template>
              <template #cell(vastTagUrlTv)="data">
                <span>{{ data.item.node.vastTagUrlTv }}</span>
              </template>
              <template #cell(isActive)="data">
                <b-button
                  :variant="data.item.node.isActive ? 'outline-success' : 'outline-danger'"
                  @click="updateActive(data.item.node)"
                >
                  {{ data.item.node.isActive ? $t('ecommerce.public') : $t('ecommerce.NoPublic') }}
                </b-button>
              </template>
              <template #cell(edit)="data">
                <b-button
                  variant="primary"
                  class="d-flex align-items-center"
                  @click.stop.prevent="editCuePoint(data.item.node)"
                >
                  <feather-icon
                    icon="EditIcon"
                    size="18"
                    class="mr-50 cursor-pointer"
                  />
                  {{ $t('Edit') }}
                </b-button>
              </template>
            </b-table>
          </b-row>
        </b-card-body>
      </b-card>
    </b-overlay>
    <b-modal
      ref="AdCuePoints"
      size="lg"
      :title="$t('resFunciones.hasAdCuePoints.title')"
      hide-footer
      @hidden="hideModal()"
    >
      <div>
        <b-form @submit.prevent="validationFormInfo">
          <b-form-row class="mt-1 ">
            <b-col class="">
              <h6 class="text-primary">
                {{ $t('ConfCuePoints') }}
              </h6>
            </b-col>
            <b-col style="text-align:right; text-align: -webkit-right;">
              <b-button
                type="submit"
                class="save-button"
                variant="success"
              >
                {{ $t('dataGeneric.save') }}
              </b-button>
            </b-col>
          </b-form-row>
          <validation-observer
            ref="pointRules"
            tag="form"
          >
            <b-form-row>
              <b-col>
                <validation-provider
                  v-slot="validationContext"
                  name="nameCuePoint"
                  rules="required"
                >
                  <b-form-group label-for="nameCuePoint">
                    <label>{{ $t('firstName') }} *</label>
                    <b-form-input
                      id="nameCuePoint"
                      v-model="nameCuePoint"
                      required
                      maxlength="150"
                      :state="validationContext.errors.length > 0 ? false : null"
                      :placeholder="$t('firstName')"
                    />
                    <small class="text-danger">{{
                      validationMessage(validationContext)
                    }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-form-row>
            <b-form-row>
              <b-col>
                <b-form-group>
                  <label>{{ $t('dataGeneric.description') }}</label>
                  <b-form-textarea
                    v-model="description"
                    :placeholder="$t('dataGeneric.description')"
                  />
                </b-form-group>
              </b-col>
            </b-form-row>
            <b-form-row>
              <b-col>
                <validation-provider
                  v-slot="validationContext"
                  name="vastTagUrl"
                  rules="required"
                >
                  <b-form-group label-for="vastTagUrl">
                    <label>{{ $t('urlVast') }} *</label>
                    <b-form-input
                      id="vastTagUrl"
                      v-model="vastTagUrl"
                      required
                      maxlength="1000"
                      :state="validationContext.errors.length > 0 ? false : null"
                      :placeholder="$t('firstName')"
                    />
                    <small class="text-danger">{{
                      validationMessage(validationContext)
                    }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-form-row>
            <b-form-row>
              <b-col>
                <validation-provider
                  v-slot="validationContext"
                  name="vastTagUrlMobile"
                  rules="required"
                >
                  <b-form-group label-for="vastTagUrlMobile">
                    <label>{{ $t('urlVastMobile') }} *</label>
                    <b-form-input
                      id="vastTagUrlMobile"
                      v-model="vastTagUrlMobile"
                      required
                      maxlength="1000"
                      :state="validationContext.errors.length > 0 ? false : null"
                      :placeholder="$t('firstName')"
                    />
                    <small class="text-danger">{{
                      validationMessage(validationContext)
                    }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-form-row>
            <b-form-row>
              <b-col>
                <validation-provider
                  v-slot="validationContext"
                  name="vastTagUrlTv"
                  rules="required"
                >
                  <b-form-group label-for="vastTagUrlTv">
                    <label>{{ $t('urlVastTv') }} *</label>
                    <b-form-input
                      id="vastTagUrlTv"
                      v-model="vastTagUrlTv"
                      required
                      maxlength="1000"
                      :state="validationContext.errors.length > 0 ? false : null"
                      :placeholder="$t('firstName')"
                    />
                    <small class="text-danger">{{
                      validationMessage(validationContext)
                    }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-form-row>
          </validation-observer>
        </b-form>
      </div>
      <b-button
        class="mt-3"
        variant="outline-danger"
        block
        @click.stop.prevent="hideModal()"
      >
        {{ $t("dataGeneric.close") }}
      </b-button>
    </b-modal>
  </div>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BFormTextarea,
  BFormInput,
  BButton,
  BSpinner,
  BFormRow,
  BRow,
  BCol,
  BCard,
  BTable,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BOverlay,
} from 'bootstrap-vue'
import axios from '@axios'
import {
  messageError, utf8ToB64, showToast,
} from '@/store/functions'
import { getUserData } from '@/auth/utils'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import videojs from 'video.js'
import 'videojs-markers/dist/videojs.markers.css'
import 'videojs-markers/dist/videojs.markers.min.css'
import 'videojs-markers/dist/videojs-markers'
import 'videojs-markers/dist/videojs-markers.min'
import 'video.js/dist/video-js.css'
import 'video.js/dist/video.min'
import 'video.js/dist/video'
// eslint-disable-next-line import/no-extraneous-dependencies
import '@videojs/http-streaming/dist/videojs-http-streaming.min'
// eslint-disable-next-line import/no-extraneous-dependencies
import '@videojs/http-streaming/dist/videojs-http-streaming'

export default {
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BFormTextarea,
    BRow,
    BCol,
    BTable,
    BSpinner,
    BCard,
    BFormRow,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BOverlay,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    stop: { default: 0 },
    language: {
      type: String,
      default: null,
    },
    base: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      video: null,
      id: this.$route.params.id,
      allCueAdPoints: [],
      fileUrl: null,
      nameCuePoint: null,
      time: 0,
      duration: 1,
      description: '',
      action: '',
      imageData: null,
      idCuePoint: null,
      userData: getUserData(),
      show: false,
      required,
      mMinute: '00',
      mHour: '00',
      mSecond: '00',
      hour: 0,
      minute: 0,
      second: 0,
      row: 0,
      imageAud: false,
      vastTagUrl: '',
      vastTagUrlMobile: '',
      vastTagUrlTv: '',
    }
  },
  watch: {
    action() {
      if (this.action === 'add' || this.action === 'update') this.$refs.AdCuePoints.show()
      else this.$refs.AdCuePoints.hide()
    },
    mHour() {
      if (this.mHour.length < 2) {
        this.mHour = parseInt(`0${this.mHour}`, 10)
      }
    },
    mMinute() {
      if (this.mHour === this.hour) {
        if (this.mMinute > this.minute) {
          this.mMinute = this.minute
        }
      }
      if (this.mMinute == null) {
        this.mMinute = 0
      }
    },
    mSecond() {
      if (this.mMinute === this.minute) {
        if (this.mSecond > this.second) {
          this.mSecond = this.second
        }
      }
      if (this.mSecond == null) {
        this.mSecond = 0
      }
    },

    stop() {
      if (this.video) {
        this.video.pause()
      }
    },
  },

  mounted() {
    this.getData()
    this.allcontentResource()
  },
  beforeDestroy() {
    if (this.video) this.video.dispose()
  },
  methods: {

    updateActive(node) {
      axios
        .post('', {
          query: `
             mutation{
              updateCueAdPoints(id:"${node.id}",input:{isActive:${!node.isActive}}){
                cueAdPoints{
                  id
                  name
                }
             }
         }
         `,
        })
        .then(() => {
          showToast(this.$t('success'), 1, this)
          this.getData()
        })
        .catch(() => {
          showToast(this.$t('error'), 2, this)
        })
    },
    tiempoVideo(tipo = null) {
      let wasPlaying = null
      let wasPlaying1 = null
      let wasPlaying2 = null
      let wasPlaying3 = null
      let silencePromise = null
      let silencePromise1 = null
      let silencePromise2 = null
      let silencePromise3 = null
      let seekTime = null
      let seekTime1 = null
      let seekTime2 = null
      let seekTime3 = null
      let duration = null
      let duration2 = null
      switch (tipo) {
        case '-10':
          silencePromise = function (value) {
            if (value !== null && typeof value.then === 'function') {
              value.then(null, () => { })
            }
          }

          wasPlaying = !this.video.paused()
          if (wasPlaying) this.video.pause()

          seekTime = this.video.currentTime() - 10
          if (seekTime <= 0) {
            seekTime = 0
          }
          this.video.currentTime(seekTime)
          if (wasPlaying) silencePromise(this.video.play())

          break
        case '-1':
          silencePromise1 = function (value) {
            if (value != null && typeof value.then === 'function') {
              value.then(null, () => { })
            }
          }

          wasPlaying1 = !this.video.paused()
          if (wasPlaying1) this.video.pause()

          seekTime1 = this.video.currentTime() - 1
          if (seekTime1 <= 0) {
            seekTime1 = 0
          }
          this.video.currentTime(seekTime1)
          if (wasPlaying1) silencePromise1(this.video.play())

          break
        case '+10':
          silencePromise2 = function (value) {
            if (value != null && typeof value.then === 'function') {
              value.then(null, () => { })
            }
          }

          wasPlaying2 = !this.video.paused()
          if (wasPlaying2) this.video.pause()

          seekTime2 = this.video.currentTime() + 10
          duration = this.video.duration()
          if (seekTime2 >= duration) {
            seekTime2 = wasPlaying2 ? duration - 0.001 : duration
          }
          this.video.currentTime(seekTime2)
          if (wasPlaying2) silencePromise2(this.video.play())

          break
        case '+1':
          silencePromise3 = function (value) {
            if (value != null && typeof value.then === 'function') {
              value.then(null, () => { })
            }
          }

          wasPlaying3 = !this.video.paused()
          if (wasPlaying3) this.video.pause()

          seekTime3 = this.video.currentTime() + 1
          duration2 = this.video.duration()
          if (seekTime3 >= duration2) {
            seekTime3 = wasPlaying3 ? duration2 - 0.001 : duration2
          }
          this.video.currentTime(seekTime3)
          if (wasPlaying3) silencePromise3(this.video.play())

          break

        default:
          break
      }
    },
    editCuePoint(items) {
      this.idCuePoint = items.id
      this.nameCuePoint = items.name
      this.description = items.description
      this.startTimecode = items.startTimecode
      this.vastTagUrl = items.vastTagUrl
      this.vastTagUrlMobile = items.vastTagUrlMobile
      this.vastTagUrlTv = items.vastTagUrlTv
      this.action = 'update'
      this.timePick(this.startTimecode)
    },
    lengthHour() {
      if (this.mHour.length === 2) {
        document.getElementById('mMinute').focus()
      }
    },
    lengthMinute() {
      if (this.mMinute.length === 2) {
        document.getElementById('mSecond').focus()
      }
    },
    lengthSecond() {
      if (this.mSecond.length === 2) {
        this.timePick()
      }
    },
    deleteCueAdPoints(id) {
      this.$swal({
        title: this.$t('code.title'),
        text: this.$t('code.text'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('code.confirm'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.show = true
          const config = {
            timeout: 1000 * 60 * 30,
          }

          const data = new FormData()

          const query = `
          mutation{
            deleteMoment(id:"${id}"){
              found
            }
          }
        `

          data.append('query', query)
          axios
            .post('', data, config)
            .then(res => {
              messageError(res, this)
              this.show = false
              this.getData()
              showToast(this.$t('success'), 1, this)
              this.hideModal()
            })
            .catch(() => {
              this.show = false

              showToast(this.$t('error'), 2, this)
            })
        }
      })
    },
    setTime() {
      const silencePromise = function (value) {
        if (value != null && typeof value.then === 'function') {
          value.then(null, () => { })
        }
      }

      const wasPlaying = !this.video.paused()
      if (wasPlaying) this.video.pause()

      let seekTime = this.mHour * 3600 + this.mMinute * 60 + this.mSecond
      const duration = this.video.duration()
      if (seekTime >= duration) {
        seekTime = wasPlaying ? duration - 0.001 : duration
      }
      this.video.currentTime(seekTime)
      if (wasPlaying) silencePromise(this.video.play())
    },
    getTime() {
      try {
        const time = this.video.currentTime()
        this.time = (time).toFixed()

        if (!Number.isNaN(time)) {
          this.mHour = Math.floor(time / 3600)
          this.mHour = this.mHour < 10 ? this.mHour : this.mHour
          this.mHour = this.pad(this.mHour)
          this.mMinute = Math.floor((time / 60) % 60)
          this.mMinute = this.mMinute < 10 ? this.mMinute : this.mMinute
          this.mMinute = this.pad(this.mMinute)

          this.mSecond = (time % 60).toFixed()
          this.mSecond = this.pad(this.mSecond)
          if (this.action === 'add' || this.action === 'update') {
            this.time = (time).toFixed()
            this.generar()
          }
        }
        // eslint-disable-next-line no-empty
      } catch (error) { }
    },
    pad(n, size) {
      let s = String(n)
      while (s.length < (size || 2)) {
        s = `0${s}`
      }
      return s
    },
    validationFormInfo() {
      return new Promise(resolve => {
        this.$refs.pointRules.validate().then(success => {
          if (success) {
            resolve(true)
            this.timePick()
            this.actionMoment()
          }
        }).catch(err => { console.log(err) })
      })
    },
    actionMoment() {
      this.startTimecode = `${this.mHour}:${this.mMinute}:${this.mSecond}`
      switch (this.action) {
        case 'update':
          this.updateCuePoint(this.idCuePoint)
          break
        case 'add':
          this.createCuePoint()
          break
        default:
          break
      }
    },
    updateCuePoint(id) {
      this.show = true
      const config = {
        timeout: 1000 * 60 * 30,
      }
      if (!this.startTimecode) this.timePick()

      const data = new FormData()
      let objectVariables = []
      objectVariables = [
        { title: 'name', value: this.nameCuePoint, type: 'String' },
        { title: 'description', value: this.description, type: 'String' },
        { title: 'startTimecode', value: this.startTimecode, type: 'Time' },
        { title: 'vastTagUrl', value: this.vastTagUrl, type: 'String' },
        { title: 'vastTagUrlMobile', value: this.vastTagUrlMobile, type: 'String' },
        { title: 'vastTagUrlTv', value: this.vastTagUrlTv, type: 'String' },
      ]

      const variables = {}
      let mutation = 'mutation('
      let query = `{updateCueAdPoints(id:"${id}",input:{\n
      `
      objectVariables.forEach(element => {
        variables[element.title] = element.value
        mutation += `$${element.title}:${element.type},\n`
        query += `${element.title}:$${element.title},\n`
      })
      mutation += ')'
      query += `}){
        cueAdPoints{
              id
            }
        }
      }`
      query = mutation + query

      data.append('query', query)
      data.append('variables', JSON.stringify(variables))
      axios
        .post('', data, config)
        .then(res => {
          messageError(res, this)
          this.show = false
          this.getData()
          showToast(this.$t('success'), 1, this)
          // this.addCuePoints('vac')
        })
        .catch(() => {
          this.show = false

          showToast(this.$t('error'), 2, this)
        })
    },
    createCuePoint() {
      this.show = true

      const config = {
        timeout: 1000 * 60 * 30,
      }
      const data = new FormData()
      const encondedId = utf8ToB64(`content:${this.id}`)

      let objectVariables = []
      objectVariables = [
        { title: 'name', value: this.nameCuePoint, type: 'String!' },
        { title: 'description', value: this.description, type: 'String' },
        { title: 'startTimecode', value: this.startTimecode, type: 'Time!' },
        { title: 'vastTagUrl', value: this.vastTagUrl, type: 'String!' },
        { title: 'vastTagUrlMobile', value: this.vastTagUrlMobile, type: 'String!' },
        { title: 'vastTagUrlTv', value: this.vastTagUrlTv, type: 'String!' },
        { title: 'content', value: encondedId, type: 'ID!' },
      ]

      const variables = {}
      let mutation = 'mutation('
      let query = `{createCueAdPoints(input:{\n
        client:"${this.userData.profile.client.id}",\n
        isActive:true,\n
      `
      objectVariables.forEach(element => {
        variables[element.title] = element.value
        mutation += `$${element.title}:${element.type},\n`
        query += `${element.title}:$${element.title},\n`
      })
      mutation += ')'
      query += `}){
        cueAdPoints{
              id
            }
        }
      }`
      query = mutation + query

      data.append('query', query)
      data.append('variables', JSON.stringify(variables))
      axios
        .post('', data, config)

        .then(res => {
          messageError(res, this)

          this.show = false
          this.getData()
          showToast(this.$t('success'), 1, this)
          this.hideModal()
        })
        .catch(res => {
          console.log(res)
          this.show = false
          showToast(this.$t('error'), 2, this)
        })
    },
    addCuePoints(type = 'add') {
      this.action = type
      if (type === 'add') {
        this.idCuePoint = ''
        this.nameCuePoint = ''
        this.description = ''
        this.vastTagUrl = ''
        this.vastTagUrlMobile = ''
        this.vastTagUrlTv = ''
        this.startTimecode = `${this.mHour}:${this.mMinute}:${this.mSecond}`
      }
    },
    hideModal() {
      this.$refs.AdCuePoints.hide()
      this.addCuePoints('vac')
    },
    timePick(seekTime = null) {
      let st = seekTime
      const silencePromise = function (value) {
        if (value != null && typeof value.then === 'function') {
          value.then(null, () => { })
        }
      }
      this.video.play()
      this.video.pause()
      const wasPlaying = !this.video.paused()
      if (wasPlaying) this.video.pause()

      const h = parseInt(this.mHour, 10) * 3600
      const m = parseInt(this.mMinute, 10) * 60
      if (!st) {
        st = parseInt(h, 10) + parseInt(m, 10) + parseInt(this.mSecond, 10)
      } else {
        const [hTime, mTime, sTime] = st.split(':')
        st = parseInt(parseInt(hTime, 10) * 3600, 10) + parseInt(parseInt(mTime, 10) * 60, 10) + parseInt(sTime, 10)
      }

      const duration = this.video.duration()
      if (st >= duration) {
        st = wasPlaying ? duration - 0.001 : duration
      }
      this.video.currentTime(st)
      if (wasPlaying) silencePromise(this.video.play())

      // this.startTimecode = `${this.mHour}:${this.mMinute}:${this.mSecond}`
    },
    validationMessage(data) {
      try {
        if (typeof data !== 'undefined' && data !== null) {
          const keyContent = Object.keys(data.failedRules)
          if (keyContent.includes('required')) {
            return this.$t('required')
          }
        }
      } catch (error) {
        console.log(error)
      }
      return ''
    },

    allcontentResource() {
      const encondedId = utf8ToB64(`content:${this.id}`)
      axios
        .post('', {
          query: `
         query{
          contentsResource(content:"${encondedId}",isInitial:true){
            edges{
              node{
                isInitial
                resource{
                  name
                  videotype
                  inputStream
                  passwordStream
                  id
                  directUrl
                  bucketUrl
                  file{
                    fileUrl
                  }
                }
                content
              }
            }
          }
          }
        `,
        })
        .then(response => {
          messageError(response, this)

          try {
            const contentsResource = response.data.data.contentsResource.edges[0]

            try {
              this.fileUrl = contentsResource.node.resource.file.fileUrl
            } catch (error) {
              this.fileUrl = contentsResource.node.resource.directUrl
            }
            // eslint-disable-next-line no-empty
          } catch (error) { }

          if (this.video) {
            if (this.fileUrl != null && this.fileUrl.length !== 0) {
              if (this.fileUrl.includes('m3u8')) {
                this.video.src({
                  type: 'application/x-mpegURL',
                  src: this.fileUrl,
                })
              } else {
                this.video.src({
                  type: 'video/mp4',
                  src: this.fileUrl,
                })
              }
            }
          }
        })
    },
    videoMaker() {
      this.video = videojs('cue-video')

      this.video.on('ready', function () {
        try {
          this.video.vhs = null
          // eslint-disable-next-line no-empty
        } catch (error) { }
      })
      const time = this.video.duration()

      if (!Number.isNaN(time)) {
        this.hour = Math.floor(time / 3600)
        this.minute = Math.floor((time / 60) % 60)
        this.minute = this.minute < 10 ? `0${this.minute}` : this.minute
        this.second = time % 60
        this.second = this.second < 10 ? `0${this.second}` : `${this.second}`

        try {
          this.second = this.second ? this.second.split('.')[0] : this.second

          // eslint-disable-next-line no-empty
        } catch (error) { }
      }

      if (this.allCueAdPoints.length !== 0) {
        try {
          this.video.markers.removeAll()
          this.allCueAdPoints.forEach(
            element => {
              const [hTime, mTime, sTime] = element.node.startTimecode.split(':')
              const startTime = parseInt(parseInt(hTime, 10) * 3600, 10) + parseInt(parseInt(mTime, 10) * 60, 10) + parseInt(sTime, 10)
              this.video.markers.add([
                {
                  time: startTime,
                  text: element.node.name,
                  overlayText: element.node.name,
                  // duration: element.node.duration,
                },
              ])
            },
          )
          // eslint-disable-next-line no-empty
        } catch (e) {
        }
      } else {
        try {
          this.video.markers.removeAll()
          // eslint-disable-next-line no-empty
        } catch (error) { }
      }

      try {
        this.video.markers({
          markerStyle: {
            width: '7px',
            'border-radius': '30%',
            'background-color': 'red',
          },
          markerTip: {
            display: true,
            text(marker) {
              return marker.text
            },
            time(marker) {
              return marker.time
            },
          },
          breakOverlay: {
            display: true,
            displayTime: 5,
            style: {
              width: '100%',
              height: '20%',
              'background-color': 'rgba(0,0,0,0.7)',
              color: 'white',
              'font-size': '17px',
            },
            text(marker) {
              return `<div class="mt-1"><span>${marker.overlayText}</span></div>`
            },
          },
          onMarkerClick() {
            // console.log("Click: " + marker.time);
          },
          onMarkerReached() {
            // console.log("Reached: " + marker.time);
          },
          // markers: this.mark(),
        })
        // eslint-disable-next-line no-empty
      } catch (error) { }
    },
    getData() {
      axios
        .post('', {
          query: `{
                allCueAdPoints(content:"${utf8ToB64(`id:${this.id}`)}",orderBy:"start_timecode") {
                    edges {
                        node {
                                id
                                name
                                description
                                vastTagUrl
                                vastTagUrlMobile
                                vastTagUrlTv
                                startTimecode
                                isActive
                                content {
                                    id

                                }
                            }
                        }
                    }
                    allContents(id:"${this.id}") {
                    edges {
                        node {
                                id
                                type
                                name(lang:"${this.language}",getDefault:true)
                                imageUrl(lang:"${this.language}",getDefault:true)
                                image
                                alternativeImageUrl(lang:"${this.language}",getDefault:true)
                                alternativeImage
                            }
                        }
                    }
                }`,
        })
        .then(res => {
          messageError(res, this)
          this.allCueAdPoints = res.data.data.allCueAdPoints.edges
          try {
            this.videoMaker()
            // eslint-disable-next-line no-empty
          } catch (error) { }
        })
    },

  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";

#cueAdPoints .actions {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: rgb(0 15 51 / 73%);
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
}

#cueAdPoints .row {
  padding: 1.3rem;
}

#cueAdPoints .row .col {
  font-size: 1.285rem !important;
}

#cueAdPoints .card-title {
  font-weight: 500;
}

#cueAdPoints .scroll {
  max-width: 100%;
  flex-flow: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 120vh;
}

#cueAdPoints ::-webkit-scrollbar {

  display: none;
}

#cueAdPoints .trash:hover {
  color: rgb(109, 0, 0);
}

#cueAdPoints .edit:hover {
  color: rgb(0, 83, 7);
}

#cueAdPoints #acortar {
  width: 30vh;
  color: white;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#cueAdPoints #acortarPadre {
  overflow: hidden;
  white-space: nowrap;
}

.video-js .vjs-tech {
  position: inherit;
  height: inherit !important;
}

#cueAdPoints .input-image {
  display: block;

  background-size: cover;
  background-position: center center;
}

#cueAdPoints .placeholder {
  background: #f0f0f0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
  color: #333;
  font-size: 18px;
  font-family: Helvetica;
}

#cueAdPoints .vjs-break-overlay {
  z-index: 0 !important;
}
</style>
