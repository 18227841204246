<template>
  <div id="moments">
    <b-overlay
      variant="transparent"
      :opacity="1.00"
      :blur="'2px'"
      rounded="lg"
      :show="show"
    >
      <b-card no-body>
        <b-card-header>
          <b-card-title>{{ $t('moments') }}</b-card-title>
        </b-card-header>
        <b-card-body>
          <b-row>
            <b-col
              md="9"
              align-self="baseline"
              class="
                              embed-responsive
                              embed-responsive-16by9
                              mr-1"
            >
              <video
                id="moment-ai-video"
                controls
                class="video-js vjs-default-skin vjs-big-play-centered embed-responsive-item "
                data-setup="{&quot;liveui&quot;: true}"
                crossOrigin="dashboard.interactvty.com"
              >
                <source
                  :src="fileUrl"
                  :type="fileUrl.includes('m3u8')
                    ? 'application/x-mpegURL'
                    : fileUrl.includes('mp4')
                      ? 'video/mp4'
                      : 'audio/mp3'
                  "
                >
              </video>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>

import {
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BOverlay,
} from 'bootstrap-vue'
import axios from '@axios'
import {
  messageError, utf8ToB64, showToast,
} from '@/store/functions'
import { getUserData } from '@/auth/utils'
import { required } from '@validations'
import videojs from 'video.js'
import 'videojs-markers/dist/videojs.markers.css'
import 'videojs-markers/dist/videojs.markers.min.css'
import 'videojs-markers/dist/videojs-markers'
import 'videojs-markers/dist/videojs-markers.min'
import 'video.js/dist/video-js.css'
import 'video.js/dist/video.min'
import 'video.js/dist/video'
// eslint-disable-next-line import/no-extraneous-dependencies
import '@videojs/http-streaming/dist/videojs-http-streaming.min'
// eslint-disable-next-line import/no-extraneous-dependencies
import '@videojs/http-streaming/dist/videojs-http-streaming'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BOverlay,
  },
  props: {
    stop: { default: 0 },
    language: {
      type: String,
      default: null,
    },
    base: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      video: null,
      id: this.$route.params.id,
      allMoments: [],
      fileUrl: null,
      name: null,
      time: 0,
      duration: 1,
      description: null,
      action: '',
      imageData: null,
      image: null,
      defaultLanguage: 'es',
      imageBlob: null,
      idMoment: null,
      canvasImage: false,
      userData: getUserData(),
      canvas: null,
      show: false,
      required,
      mMinute: '00',
      mHour: '00',
      mSecond: '00',
      hour: 0,
      minute: 0,
      second: 0,
      type: 'NOR',
      row: 0,
      imageAud: false,
    }
  },
  watch: {
    canvasImage() {
      this.generar()
    },

  },

  mounted() {
    this.getDefaultLanguage()
    this.getData()
    this.allcontentResource()
  },
  beforeDestroy() {
    if (this.video) this.video.dispose()
  },
  methods: {
    getDefaultLanguage() {
      axios
        .post('', {
          query: `
               query{
               client(id:"${this.userData.profile.client.id}"){
                   defaultLanguage
                   availableLanguages
               }
           }
           `,
        })
        .then(res => {
          this.defaultLanguage = res.data.data.client.defaultLanguage
        })
        .catch(() => {
        })
    },
    setTime() {
      const silencePromise = function (value) {
        if (value != null && typeof value.then === 'function') {
          value.then(null, () => { })
        }
      }
      const wasPlaying = !this.video.paused()
      if (wasPlaying) this.video.pause()

      let seekTime = this.mHour * 3600 + this.mMinute * 60 + this.mSecond
      const duration = this.video.duration()
      if (seekTime >= duration) {
        seekTime = wasPlaying ? duration - 0.001 : duration
      }
      this.video.currentTime(seekTime)
      if (wasPlaying) silencePromise(this.video.play())
    },
    getTime() {
      try {
        const time = this.video.currentTime()
        this.time = (time).toFixed()

        if (!Number.isNaN(time)) {
          this.mHour = Math.floor(time / 3600)
          this.mHour = this.mHour < 10 ? this.mHour : this.mHour
          this.mHour = this.pad(this.mHour)
          this.mMinute = Math.floor((time / 60) % 60)
          this.mMinute = this.mMinute < 10 ? this.mMinute : this.mMinute
          this.mMinute = this.pad(this.mMinute)

          this.mSecond = (time % 60).toFixed()
          this.mSecond = this.pad(this.mSecond)
          if (this.action === 'add' || this.action === 'update') {
            this.time = (time).toFixed()
            this.generar()
          }
        }
        // eslint-disable-next-line no-empty
      } catch (error) { }
    },
    pad(n, size) {
      let s = String(n)
      while (s.length < (size || 2)) {
        s = `0${s}`
      }
      return s
    },
    actionMoment() {
      switch (this.action) {
        case 'update':
          this.update(this.idMoment)

          break
        case 'add':

          this.create()
          break
        default:
          break
      }
    },

    create() {
      const headers = {
        'Accept-Language': this.defaultLanguage,
        'Content-Language': this.defaultLanguage,
      }
      this.show = true

      const config = {
        timeout: 1000 * 60 * 30,
      }
      const data = new FormData()
      const encondedId = utf8ToB64(`content:${this.id}`)

      let objectVariables = []
      objectVariables = [
        { title: `name${this.language}`, value: this.name, type: 'String' },
        { title: `description${this.language}`, value: this.description, type: 'String' },
        { title: 'time', value: this.time, type: 'Int!' },
        { title: 'duration', value: this.duration === 0 ? 1 : this.duration, type: 'Int!' },
        { title: 'name', value: this.name, type: 'String!' },
        { title: 'description', value: this.description, type: 'String' },
        { title: 'content', value: encondedId, type: 'ID!' },
      ]

      const variables = {}
      let mutation = 'mutation('
      let query = `{createMoment(input:{\n
          client:"${this.userData.profile.client.id}",\n
          isActive:true,\n
        `
      objectVariables.forEach(element => {
        variables[element.title] = element.value
        mutation += `$${element.title}:${element.type},\n`
        query += `${element.title}:$${element.title},\n`
      })
      mutation += ')'
      query += `}){
          moments{
                id
              }
          }
        }`
      query = mutation + query

      if (this.imageAud) {
        if (this.image) data.append('image_aud', this.image)
        else { data.append('image_url', this.imageData) }
      } else data.append('image', this.imageBlob)

      data.append('query', query)
      data.append('variables', JSON.stringify(variables))
      axios
        .post('', data, { headers }, config)

        .then(res => {
          messageError(res, this)

          this.show = false
          this.getData()
          showToast(this.$t('success'), 1, this)
          this.addMoment('vac')
        })
        .catch(res => {
          console.log(res)
          this.show = false

          showToast(this.$t('error'), 2, this)
        })
    },

    timePick(seekTime = null) {
      let st = seekTime
      const silencePromise = function (value) {
        if (value != null && typeof value.then === 'function') {
          value.then(null, () => { })
        }
      }
      this.video.play()
      this.video.pause()
      const wasPlaying = !this.video.paused()
      if (wasPlaying) this.video.pause()

      const h = parseInt(this.mHour, 10) * 3600
      const m = parseInt(this.mMinute, 10) * 60
      if (!st) {
        st = parseInt(h, 10) + parseInt(m, 10) + parseInt(this.mSecond, 10)
      }

      const duration = this.video.duration()
      if (st >= duration) {
        st = wasPlaying ? duration - 0.001 : duration
      }
      this.video.currentTime(st)
      if (wasPlaying) silencePromise(this.video.play())
    },
    async generar() {
      this.canvasImage = true
      try {
        const video = document.getElementById('moment-video_html5_api')

        this.canvas = document.getElementById('canvas-moments')

        this.canvas.width = video.videoWidth
        this.canvas.height = video.videoHeight

        this.canvas
          .getContext('2d')
          .drawImage(video, 0, 0, this.canvas.width, this.canvas.height)

        this.imageBlob = await new Promise(resolve => this.canvas.toBlob(resolve, 'image/png'))
        // eslint-disable-next-line no-empty
      } catch (error) {
      }
    },

    videoMaker() {
      this.video = videojs('moment-video')

      this.video.on('ready', function () {
        try {
          this.video.vhs = null
          // eslint-disable-next-line no-empty
        } catch (error) { }
      })
      const time = this.video.duration()

      if (!Number.isNaN(time)) {
        this.hour = Math.floor(time / 3600)
        this.minute = Math.floor((time / 60) % 60)
        this.minute = this.minute < 10 ? `0${this.minute}` : this.minute
        this.second = time % 60
        this.second = this.second < 10 ? `0${this.second}` : `${this.second}`

        try {
          this.second = this.second ? this.second.split('.')[0] : this.second

          // eslint-disable-next-line no-empty
        } catch (error) { }
      }

      if (this.allMoments.length !== 0) {
        try {
          this.video.markers.removeAll()
          this.allMoments.forEach(
            element => {
              this.video.markers.add([
                {
                  time: element.node.time,
                  text: element.node.name,
                  overlayText: element.node.name,
                  duration: element.node.duration,
                  image: element.node.image,
                },
              ])
            },
          )
          // eslint-disable-next-line no-empty
        } catch (e) {
        }
      } else {
        try {
          this.video.markers.removeAll()
          // eslint-disable-next-line no-empty
        } catch (error) { }
      }

      try {
        this.video.markers({
          markerStyle: {
            width: '7px',
            'border-radius': '30%',
            'background-color': 'red',
          },
          markerTip: {
            display: true,
            text(marker) {
              return marker.text
            },
            time(marker) {
              return marker.time
            },
          },
          breakOverlay: {
            display: true,
            displayTime: 5,
            style: {
              width: '100%',
              height: '20%',
              'background-color': 'rgba(0,0,0,0.7)',
              color: 'white',
              'font-size': '17px',
            },
            text(marker) {
              return `<div class="mt-1"><img src="${marker.image}" style="width: 100px;" class="mr-2"><span>${marker.overlayText}</span></div>`
            },
          },
          onMarkerClick() {
            // console.log("Click: " + marker.time);
          },
          onMarkerReached() {
            // console.log("Reached: " + marker.time);
          },
          // markers: this.mark(),
        })
        // eslint-disable-next-line no-empty
      } catch (error) { }
    },

  },
}
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";

#moments .actions {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background: rgb(0 15 51 / 73%);
    opacity: 1;
    transition: opacity 0.2s ease-in-out;
}

#moments .row {
    padding: 1.3rem;
}

#moments .row .col {
    font-size: 1.285rem !important;
}

#moments .card-title {
    font-weight: 500;
}

#moments .scroll {
    max-width: 100%;
    flex-flow: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 120vh;
}

/* #moments .list {
    overflow: hidden;
    overflow-y: scroll;
    height: 38vh;
  } */

#moments ::-webkit-scrollbar {

    display: none;
}

#moments .trash:hover {
    color: rgb(109, 0, 0);
}

#moments .edit:hover {
    color: rgb(0, 83, 7);
}

#moments #acortar {
    width: 30vh;
    color: white;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

#moments #acortarPadre {
    overflow: hidden;
    white-space: nowrap;
}

.video-js .vjs-tech {
    position: inherit;
    height: inherit !important;
}

#moments .input-image {
    display: block;

    background-size: cover;
    background-position: center center;
}

#moments .placeholder {
    background: #f0f0f0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;
    color: #333;
    font-size: 18px;
    font-family: Helvetica;
}
</style>
