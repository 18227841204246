var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-col',{class:!_vm.disabled ? 'cursor-pointer' : '',attrs:{"id":"imageElement","cols":_vm.colsLength,"md":_vm.mdLength,"align-self":"stretch"},on:{"dragover":function (event) { return event.preventDefault(); },"drop":function($event){!_vm.disabled ? _vm.addFiles($event) : ''}}},[_c('label',{staticClass:"d-flex justify-content-between mb-1"},[_c('div',[_vm._v(" "+_vm._s(_vm.label)+_vm._s(_vm.isRequired ? ' *' : '')+" "),(!_vm.isEmpty(_vm.labelInfo))?_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top.v-primary",value:(_vm.labelInfo),expression:"labelInfo",modifiers:{"hover":true,"top":true,"v-primary":true}}],staticClass:"ml-1 cursor-pointer",attrs:{"icon":"InfoIcon","size":"15"}}):_vm._e()],1),_c('div',[(!_vm.isEmpty(_vm.aiText))?_c('b-img',{staticClass:"cursor-pointer mr-1",staticStyle:{"width":"18px","border-radius":"50%"},attrs:{"src":_vm.aiColor},on:{"click":_vm.clickAiImage}}):_vm._e(),(_vm.imageDataUrl && _vm.isEmpty(_vm.imageData))?_c('feather-icon',{staticClass:"text-primary cursor-pointer",attrs:{"icon":"DownloadIcon","size":"15"},on:{"click":_vm.downloadFile}}):_vm._e(),(_vm.imageDataUrl && _vm.canDelete && !_vm.disabled)?_c('feather-icon',{staticClass:"text-danger cursor-pointer ml-1",attrs:{"icon":"XOctagonIcon","size":"15"},on:{"click":_vm.deleteImage}}):_vm._e()],1)]),_c('validation-observer',{ref:"ArchivosRules",attrs:{"tag":"form"}},[_c('validation-provider',{attrs:{"name":"imageOrigin","rules":_vm.isRequired ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.mediaOriginSelected == 'BUK')?_c('b-form-group',{staticClass:"\n        dropContainer\n          mb-2\n          mr-1\n          embed-responsive embed-responsive-16by9\n        ",attrs:{"state":errors.length > 0 ? false : null},on:{"dragenter":function (event) { return event.preventDefault(); }}},[(!_vm.video)?_c('div',{staticClass:"base-image-input embed-responsive-item",style:({
            background: ("" + (_vm.isEmpty(_vm.imageDataSrc)
              ? ("url(" + (_vm.isEmpty(_vm.imageDataUrl)
                ? _vm.imageData
                : _vm.imageDataUrl) + ") center/contain no-repeat,transparent center/contain no-repeat")
              : ''))
          }),on:{"click":function($event){!_vm.disabled ? _vm.chooseImage(_vm.imageType) : ''}}},[(_vm.isEmpty(_vm.imageDataUrl))?_c('span',{staticClass:"placeholder embed-responsive-item"},[_c('feather-icon',{attrs:{"icon":"ImageIcon","size":"3.5x"}}),(!_vm.disabled)?_c('small',{staticStyle:{"text-align":"center"}},[_vm._v(" "+_vm._s(_vm.$t("media.dropFilesToCard"))+" ")]):_vm._e()],1):_c('b-img',{staticClass:"mb-3 embed-responsive-item",staticStyle:{"object-fit":"contain"},attrs:{"src":_vm.imageDataSrc,"fluid":"","block":"","rounded":""}}),_c('b-form-file',{staticClass:"mr-1",staticStyle:{"display":"none"},attrs:{"id":_vm.imageType,"accept":"image/*","state":errors.length > 0 ? false : null},model:{value:(_vm.imageData),callback:function ($$v) {_vm.imageData=$$v},expression:"imageData"}})],1):_c('div',{staticClass:"base-image-input embed-responsive-item",on:{"click":function($event){!_vm.disabled ? _vm.chooseImage(_vm.imageType) : ''}}},[(_vm.isEmpty(_vm.imageDataUrl))?_c('span',{staticClass:"placeholder embed-responsive-item"},[_c('feather-icon',{attrs:{"icon":"FilmIcon","size":"3.5x"}}),(!_vm.disabled)?_c('small',{staticStyle:{"text-align":"center"}},[_vm._v(" "+_vm._s(_vm.$t("media.dropFilesToCard"))+" ")]):_vm._e()],1):_c('video',{ref:"fileInputTrVid",staticClass:"base-image-input embed-responsive-item"},[_c('source',{attrs:{"src":_vm.isEmpty(_vm.imageDataSrc)
                ? _vm.imageDataUrl
                : _vm.imageDataSrc,"type":"video/mp4"}})]),_c('b-form-file',{staticClass:"mr-1",staticStyle:{"display":"none"},attrs:{"id":_vm.imageType,"accept":"video/*"},model:{value:(_vm.imageData),callback:function ($$v) {_vm.imageData=$$v},expression:"imageData"}})],1)]):_c('b-form-group',{staticClass:"\n          mb-2\n          mr-1\n          embed-responsive embed-responsive-16by9\n          ",attrs:{"state":errors.length > 0 ? false : null}},[(!_vm.video)?_c('div',{staticClass:"base-image-input embed-responsive-item urlContainer",style:({
            background: ("url('" + _vm.imageDataUrl + "') center/contain no-repeat,transparent center/contain no-repeat")
          }),on:{"click":function($event){!_vm.disabled ? _vm.chooseImage(_vm.imageType) : ''}}},[(_vm.isEmpty(_vm.imageDataUrl))?_c('span',{staticClass:"placeholder"},[_c('feather-icon',{attrs:{"icon":"ImageIcon","size":"3.5x"}})],1):_vm._e(),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.imageDataUrl),expression:"imageDataUrl"}],attrs:{"type":"text","hidden":"","state":errors.length > 0 ? false : null},domProps:{"value":(_vm.imageDataUrl)},on:{"input":function($event){if($event.target.composing){ return; }_vm.imageDataUrl=$event.target.value}}})]):_c('div',{staticClass:"base-image-input embed-responsive-item",on:{"click":function($event){!_vm.disabled ? _vm.chooseImage(_vm.imageType) : ''}}},[(_vm.isEmpty(_vm.imageDataUrl))?_c('span',{staticClass:"placeholder"},[_c('feather-icon',{attrs:{"icon":"FilmIcon","size":"3.5x"}})],1):_c('video',{ref:"fileInputTrVid",staticClass:"base-image-input embed-responsive-item"},[_c('source',{attrs:{"src":_vm.imageDataUrl,"type":"video/mp4"}})])])]),(_vm.isEmpty(_vm.imageDataUrl))?_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(_vm.$t('requiere') + ' ' + _vm.label)+" ")]):_vm._e()]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }