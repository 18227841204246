<template>
  <b-form @submit="saveChanges">
    <b-card>
      <b-card-header>
        <b-card-title class="d-flex">
          {{ $t("extendedFields") }}
        </b-card-title>
        <div>
          <b-button
            class="mr-1"
            variant="primary"
            @click="addNewfield"
          >
            {{ $t("fieldAdd") }}
          </b-button>
          <b-button
            variant="success"
            type="submit"
          >
            {{
              $t("dataGeneric.save")
            }}
          </b-button>
        </div>
      </b-card-header>
      <b-card-body>
        <transition
          name="fade"
          mode="out-in"
        >
          <div
            v-if="!isEmpty(fields)"
            key="element1"
          >
            <b-card
              v-for="(field, index) in fields"
              :key="'card_' + index"
              no-body
              class="field-box"
            >
              <b-row
                class="align-items-center"
                no-gutters
              >
                <b-col md="3">
                  <b-form-group
                    :invalid-feedback="$t('required')"
                    :label="$t('campo')"
                  >
                    <b-form-input
                      :id="'input_' + index"
                      v-model="field.key"
                      required
                      maxlength="150"
                      :placeholder="$t('field')"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  md="6"
                  class="ml-1"
                >
                  <b-form-group
                    :invalid-feedback="$t('required')"
                    :label="$t('value')"
                  >
                    <b-form-input
                      :id="'input_' + index"
                      v-model="field.value"
                      required
                      maxlength="150"
                      :placeholder="$t('fieldVal')"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  md="1"
                  class="d-flex justify-content-center"
                >
                  <b-button
                    class="btn-icon rounded-circle"
                    variant="danger"
                    @click="dropField(index)"
                  >
                    <feather-icon
                      icon="XCircleIcon"
                      size="20"
                    />
                  </b-button>
                </b-col>
              </b-row>
            </b-card>
          </div>
          <b-jumbotron
            v-else
            key="element2"
            bg-variant="transparent"
            border-variant="primary"
            :header="$t('createFields')"
            class="text-center"
          >
            <p class="text-primary">
              {{ $t('noExistFields') }}
            </p>

            <b-col
              class="text-center m-10 p-5"
              align-self="center"
            >
              <div>
                <b-button
                  class="mr-1"
                  variant="primary"
                  @click="addNewfield"
                >
                  {{ $t("fieldAdd") }}
                </b-button>
              </div>
            </b-col>
          </b-jumbotron>
        </transition>
      </b-card-body>
    </b-card>
  </b-form>
</template>
<script>
import {
  BRow,
  BCol,
  BButton,
  BCardBody,
  BFormInput,
  BFormGroup,
  BCardHeader,
  BJumbotron,
  BCard,
  BForm,
  BCardTitle,
} from 'bootstrap-vue'
import { getUserData } from '@/auth/utils'
import axios from '@axios'
import { isEmpty, messageError, showToast } from '@/store/functions'

export default {
  components: {
    BRow,
    BCardBody,
    BCardHeader,
    BJumbotron,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BButton,
    BCol,
  },
  props: {
    jsonData: {
      default: null,
    },
    code: {
      type: String,
      default: null,
    },

  },
  data() {
    return {
      isEmpty,
      userData: getUserData(),
      fields: [],
    }
  },
  mounted() {
    if (this.jsonData) this.fillFields()
  },
  methods: {
    dropField(index) {
      this.$swal({
        title: this.$t('dataGeneric.atention'),
        text: this.$t('infoField'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('next'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.fields.splice(index, 1)
          // eslint-disable-next-line no-restricted-globals
          this.saveChanges(event)
        }
      }).catch(() => {
        showToast(this.$t('error'), 0, this)
      })
    },
    fillFields() {
      const data = JSON.parse(this.jsonData)
      Object.keys(data).forEach(key => {
        this.fields.push({ key, value: data[key] })
      })
    },
    addNewfield() {
      this.fields.push({ key: '', value: '' })
    },
    saveChanges(event) {
      event.preventDefault()

      let extraData = this.fields.reduce((acc, item) => {
        if (item.key) {
          acc[item.key] = item.value
        }
        return acc
      }, {})
      extraData = JSON.stringify(extraData)

      const query = `mutation($id: ID!,$extraData:JSONString){
                updateContents(id:$id,input:{
                  extraData:$extraData
                }){
                  content{
                    id
                    name
                    isActive
                  }
                }
              }`
      const variables = { extraData, id: this.code }
      axios
        .post('', { query, variables }).then(res => {
          messageError(res, this)
          showToast(this.$t('success'), 1, this)
        }).catch(() => {
          showToast(this.$t('error'), 0, this)
        })
    },
  },
}
</script>
<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.2s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
