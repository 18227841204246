<template>
  <div id="live-intro">
    <b-overlay
      variant="transparent"
      :show="show"
      :opacity="1.00"
      :blur="'2px'"
      rounded="lg"
    >
      <b-card no-body>
        <b-card-header>
          <b-card-title>{{ $t('liveIntro') }}</b-card-title>
          <b-button
            variant="success"
            class="save-button"
            @click="validationFormInfo"
          >
            {{ $t('dataGeneric.save') }}
          </b-button>
        </b-card-header>
        <b-card-body>
          <b-form>
            <validation-observer
              ref="LiveRules"
              tag="form"
            >
              <b-row>
                <b-col>
                  <b-form-group
                    class="mb-2 mr-1"
                    :label="$t('dateInit')"
                  >
                    <flat-pickr
                      id="liveStart"
                      v-model="liveStart"
                      name="liveStart"
                      class="form-control"
                      :placeholder="$t('dateInit')"
                      :config="{
                        enableTime: true,
                        enableSeconds: true,
                        dateFormat: 'Y-m-d H:i:S',
                        minDate: 'today'
                      }"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group
                    class="mb-2 mr-1"
                    :label="$t('dateEnd')"
                  >
                    <flat-pickr
                      id="liveEnd"
                      v-model="liveEnd"
                      name="liveEnd"
                      class="form-control"
                      :placeholder="$t('dateEnd')"
                      :config="{
                        enableTime: true,
                        enableSeconds: true,
                        dateFormat: 'Y-m-d H:i:S',
                        minDate: liveStart
                      }"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group
                    class="mb-2 mr-1"
                    :label="$t('hasIntro')"
                  >
                    <b-form-checkbox
                      v-model="hasIntro"
                      switch
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row
                v-if="hasIntro"
                class="align-items-center"
              >
                <b-col>
                  <validation-provider
                    v-slot="validationContext"
                    name="resourceName"
                    rules="required"
                  >
                    <b-form-group
                      class="mb-2 mr-1"
                      :label="$t('resPrev')"
                    >
                      <b-form-input
                        v-model="resourceName"
                        :state="validationContext.errors.length > 0 ? false : null"
                        disabled
                        :placeholder="$t('resPrev')"
                      />
                      <small class="text-danger">{{
                        validationMessage(validationContext)
                      }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col md="2">
                  <b-button
                    variant="primary"
                    @click="$refs['my-modal'].show()"
                  >
                    {{ $t('select') }}
                  </b-button>
                </b-col>
              </b-row>
            </validation-observer>
          </b-form>
        </b-card-body>
      </b-card>
    </b-overlay>
    <b-modal
      ref="my-modal"
      size="xl"
      hide-footer
      :title="$t('editContent.recSelect')"
    >
      <div class="d-block text-center">
        <resources-selector
          :videotype="'PRE'"
          :msg="''"
          :sponsor="isSponsor"
          @data="seleccionarRecurso"
        />
      </div>
      <b-button
        class="mt-3"
        variant="outline-danger"
        block
        @click="hideModal"
      >
        {{ $t("dataGeneric.close") }}
      </b-button>
    </b-modal>
  </div>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
  BFormCheckbox,
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BOverlay,
  BModal,
} from 'bootstrap-vue'
import axios from '@axios'
import { showToast } from '@/store/functions'
import { getUserData } from '@/auth/utils'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import flatPickr from 'vue-flatpickr-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'flatpickr/dist/flatpickr.css'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ResourcesSelector from '../../../common/ResourcesSelector.vue'

export default {
  components: {
    BForm,
    flatPickr,
    BModal,
    BFormGroup,
    BFormCheckbox,
    BFormInput,
    BButton,
    BRow,
    ResourcesSelector,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BOverlay,
    ValidationProvider,
    ValidationObserver,
  },

  props: {
    stop: {
      default: 0,
    },
    isSponsor: {
      type: Boolean,
      default: false,
    },

  },
  data() {
    return {
      id: this.$route.params.id,
      name: null,
      userData: getUserData(),
      show: false,
      liveStart: null,
      liveEnd: null,
      resourceId: null,
      resourceName: null,
      hasIntro: false,
      required,
    }
  },
  mounted() {
    this.getData()
    this.liveStart = new Date()
    this.liveEnd = new Date()
  },
  methods: {
    validationFormInfo() {
      return new Promise(resolve => {
        this.$refs.LiveRules.validate().then(success => {
          if (success) {
            resolve(true)
            this.update()
          }
        }).catch(err => { console.log(err) })
      })
    },
    update() {
      this.show = true
      const config = {
        timeout: 1000 * 60 * 30,
      }
      const data = new FormData()
      const query = `
        mutation($hasIntro: Boolean,$preResource: ID, $liveEnd: DateTime, $liveStart: DateTime){
          updateContents(id:"${this.id}",input:{
          hasIntro:$hasIntro,
          preResource: $preResource,
          liveEnd:$liveEnd,
          liveStart: $liveStart
        }){
          content{
              id
            }
          }
        }
        `
      const variables = {
        hasIntro: this.hasIntro,
        preResource: this.resourceId,
        liveStart: this.fechaFormato(this.liveStart),
        liveEnd: this.fechaFormato(this.liveEnd),
      }

      data.append('query', query)
      data.append('variables', JSON.stringify(variables))
      axios
        .post('', data, config)
        .then(() => {
          this.show = false

          this.getData()
          showToast(this.$t('success'), 1, this)
        })
        .catch(() => {
          this.show = false

          showToast(this.$t('error'), 2, this)
        })
    },
    fechaFormato(value) {
      const fecha = new Date(value)

      return value != null ? fecha : value
    },
    validationMessage(data) {
      try {
        if (typeof data !== 'undefined' && data !== null) {
          const keyContent = Object.keys(data.failedRules)
          if (keyContent.includes('required')) {
            return this.$t('required')
          }
        }
      } catch (error) {
        console.log(error)
      }
      return ''
    },
    seleccionarRecurso(data) {
      this.hideModal()
      const [id, name] = data
      this.resourceId = id
      this.resourceName = name

      this.$toast({
        component: ToastificationContent,
        props: {
          title: this.$t('code.rec'),
          icon: 'BellIcon',
          text: this.$t('code.recSel', { resourceName: this.resourceName }),
          variant: 'success',
        },
      })
    },
    hideModal() {
      this.$refs['my-modal'].hide()
    },
    getData() {
      axios
        .post('', {
          query: `{
                allContents(id:"${this.id}") {
                    edges {
                        node {
                                id
                                hasIntro
                                liveEnd
                                liveStart
                                preResource {
                                  id
                                  name
                                }
                            }
                        }
                    }
                }`,
        })
        .then(res => {
          const content = res.data.data.allContents.edges[0].node
          this.hasIntro = content.hasIntro

          if (content.liveStart) {
            this.liveStart = content.liveStart
            this.liveEnd = content.liveEnd
          }
          if (content.preResource) {
            this.resourceName = content.preResource.name
            this.resourceId = content.preResource.id
          }
        })
    },

  },
}
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

#live-intro .actions {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: rgb(0 15 51 / 73%);
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
}

#live-intro .row {
  padding: 1.3rem;
}

#live-intro .row .col {
  font-size: 1.285rem !important;
}

#live-intro .card-title {
  font-weight: 500;
}

#live-intro .scroll {
  max-width: 100%;
  flex-flow: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

}

#live-intro .list {
  overflow: hidden;
  overflow-y: scroll;
  height: 380px;
}

#live-intro ::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  display: none;
}

#live-intro .trash:hover {
  color: rgb(109, 0, 0);
}

#live-intro .edit:hover {
  color: rgb(0, 83, 7);
}

#live-intro #acortar {
  width: 300px;
  color: white;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#live-intro #acortarPadre {
  overflow: hidden;
  white-space: nowrap;
}

.video-js .vjs-tech {
  position: inherit;
  height: inherit !important;
}

#live-intro .input-image {
  display: block;

  background-size: cover;
  background-position: center center;
}
</style>
