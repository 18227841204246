
import { getUserData } from "@/auth/utils";

export default function configCategoryType(all = false, subCategory = false, link = false, filtered = false) {
    const user = getUserData()
    let elearningPlatform = false
    try {
        elearningPlatform = user.profile.client.isElearningPlatform
    } catch (error) {}
    const typesEdu = {
        COU: "CCOU",
        MOD: "CMOD",
        UNI: "CUNI",
    }
    let types = {}
    if (all) {
        types = {
            NOR: "CNOR",
            FET: "CFET",
            FIL: "CFIL",
            SER: "CSER",
            DIR: "CDIR",
            PRO: "CPRO",
            POD: "CPOD",
            FAV: "CFAV",
            FAC: "CFAC",
            COW: "CCOW",
            LAS: "CLAS",
            NEW: "CNEW",
            MOS: "CMOS",
            FOY: "CFOY",
            SUM: "CSUM",
            LIN: "CLIN",
            AUL: "CAUL",
        }
    } else {
        if (subCategory) {
            types = {
                NOR: "CNOR",
                DIR: "CDIR",
                SER: "CSER",
                FET: "CFET",
                PRO: "CPRO",
                POD: "CPOD",
                AUL: "CAUL",


            }
        } else {
            types = {
                NOR: "CNOR",
                DIR: "CDIR",
                POD: "CPOD",
                FAV: "CFAV",
                PRO: "CPRO",
                SER: "CSER",
                FET: "CFET",
                FAC: "CFAC",
                COW: "CCOW",
                LAS: "CLAS",
                NEW: "CNEW",
                MOS: "CMOS",
                FOY: "CFOY",
                SUM: "CSUM",
                AUL: "CAUL",

            }
        }
        if (link)
            types['LIN'] = "CLIN"
        if (filtered)
            types['FIL'] = "CFIL"
    }

    if (elearningPlatform) {
        types = { ...types, ...typesEdu }
    }
    return types;
}